import React,{useRef,useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Radio,
  Button,
  DatePicker,
  Popconfirm,
  message,
  Modal,
  Space
 } from 'antd';
import qs from 'qs'
import moment from 'moment';
import OnlinePayDetail1 from './OnlinePayDetail1'
import MyModel1 from './MyModel1';
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'

const FormItem = Form.Item;
const { Option } = Select;
const { RangePicker } = DatePicker;
const itemStyle = {
     width:'380px',
     marginRight:'15px'
}
const labelStyleObj = {
    labelCol:{ span: 7},
    wrapperCol:{ span: 17}
}

let second = 60
function OnlinePay(props) {
    let path = 'company:OnlinePayment'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
    const myModel1 = useRef();
    const [form] = Form.useForm();
    const initSearch = {
        date:[],
    }
    
    const [dayValue, setDayValue] = React.useState('');
    const [tableData,setTableData] = useState([])
    const [month, setMonth] = React.useState('');
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [waitAmount,setWaitAmount] = useState(0)
    const [sumMoney,setSumMoney] = useState(0)
    const [settlementNo,setSettlementNo] = useState()
    const [visible2, setVisible2] = React.useState(false);
    const [visible3, setVisible3] = React.useState(false);
    const [btnTxt, setBtnTxt] = React.useState('获取验证码');
    const [btnDisabled, setBtnDisabled] = React.useState(false);
    const [isDetail,setIsDetail] = useState(false)
    const [parmObj,setParmObj] = useState({})       //传给明细的参数
    const [checkObj,setCheckObj] = useState({})
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    
    const columns = [
        {
            title: '生成结算单时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 150,
            align:'center',
        },
        {
            title: '佣金账单',
            dataIndex: 'billNo',
            key: 'billNo',
            width: 180,
            align:'center',
            
        },
        {
            title: '账单月份',
            dataIndex: 'month',
            key: 'month',
            width: 180,
            align:'center',
        },
        {
            title: '放款人数',
            dataIndex: 'month',
            key: 'month',
            width: 180,
            align:'center',
        },
        {
            title: '合计放款（元）',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            width: 150,
            align:'center',
            
        },   
        {
            title: '个人佣金（元）',
            dataIndex: 'totalCommissionAmount',
            key: 'totalCommissionAmount',
            width: 150,
            align:'center',
            
        },  {
            title: '服务费（元）',
            dataIndex: 'totalServiceAmount',
            key: 'totalServiceAmount',
            width: 150,
            align:'center',
            
        }, {
            title: '税费（元）',
            dataIndex: 'totalTaxAmount',
            key: 'totalTaxAmount',
            width: 150,
            align:'center',
        },
        {
            title: '审核状态',
            dataIndex: 'checkStatus',
            key: 'checkStatus',
            width: 150,
            align:'center',
            render: (text, record) => {
                if(!record.checkStatus || record.checkStatus == '00'){
                    return '待审核'
                }else{
                    return '审核拒绝'
                }
            }
        },
        {
        title: '操作',
        key: 'action',
        width: 300,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            <Space size="middle">
                {store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a onClick={() => methodsFn.goDetail(record) }>明细</a>}
                {store.getState().userReducer.permissions.indexOf(`${path}:confirm`)>-1&&<Popconfirm
                        title="确认后将自动通知平台及时完成线上支付，确定吗？"
                        onConfirm={() => {
                            methodsFn.handlePay(record)
                        }}
                        okText="确定"
                        cancelText="取消"
                        className="marginLeft"
                        >
                        <a>确认放款</a>
                </Popconfirm>}
                {store.getState().userReducer.permissions.indexOf(`${path}:abandon`)>-1&&<Popconfirm
                        title="确定放弃支付吗？"
                        onConfirm={() => {
                            methodsFn.handleAbandon(record)
                        }}
                        okText="确定"
                        cancelText="取消"
                        className="marginLeft"
                        >
                        <a>放弃</a>
                </Popconfirm>}
            </Space>
        ),
        },
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="时间范围" name="date" style={{marginLeft:'70px'}} >
                        <RangePicker 
                            onChange={methodsFn.timeChange}
                        />
                    </FormItem>
                    <FormItem style={{with:'600px',marginLeft:'26px'}} label="">
                        <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                            <Radio value={1}>今天</Radio>
                            <Radio value={7}>近7天</Radio>
                            <Radio value={30}>近30天</Radio>
                        </Radio.Group>
                    </FormItem>
                </Row>
                <Row>
                    <FormItem label="账单月份" name="month" style={itemStyle} {...labelStyleObj}>
                        <DatePicker onChange={(date, dateString) => setMonth(dateString)} picker="month" />
                    </FormItem>
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        // 获取所有结算通道
        async getTaxEnterpriseListall(company){
            const res = await request.get('/common/getTaxEnterpriseList',{})
            setTaxEnterpriseLis(res)
        },
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1,'d').format('YYYY-MM-DD'),
            endDate = moment().format('YYYY-MM-DD');
        
            setDayValue(e.target.value)
            form.setFieldsValue({
                date:[moment(startDate),moment(endDate)]
            })
        },
        timeChange(dates,dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate),moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date:dateArr
            })
         
        },
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let beginTime = '',
                endTime = '';
            if(formObj.date.length > 1){
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            }else{
                beginTime = ''
                endTime = ''
            }
            let obj = {
                beginTime:beginTime,
                endTime:endTime,
                month:month,
                source:formObj.source,
                taxEnterpriseCode:formObj.taxEnterpriseCode,
                // paymentStatus:formObj.paymentStatus,
                checkStatus:formObj.checkStatus,
            }
            return obj
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setMonth('')
            setDayValue('')
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/company/onlinePayment/getList',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
   
        goDetail(record){
            setIsDetail(true)
            props.history.push('/admin/onlinePay?settlementNo='+record.settlementNo+'&lineType='+record.lineType)
            setParmObj({
                settlementNo:record.settlementNo,
                lineType:record.lineType,
            })
        },
        detailFn(){
            setIsDetail(false)
            props.history.push('/admin/onlinePay')
        },
        handleAbandon(record){
            request.post('/company/onlinePayment/abandon',{
                settlementNo:record.settlementNo,
            }).then(res => {
                message.success(res.msg)
                methodsFn.handleSearch() 
            })
        },
        handlePay(record){
            request.post('/company/onlinePayment/confirm',{
                settlementNo:record.settlementNo,
             }).then(res => {
                message.success(res.msg)
                methodsFn.refresh() 
             })
         },
        refresh(){
            methodsFn.handleSearch() 
        },
        handleExport(){
            request.downExport('/company/onlinePayment/export',methodsFn.getsearchObj())
        }
    }

    useEffect(() => {
        if(props.location.search){       //解决明细页面刷新问题
            methodsFn.goDetail({
                settlementNo:searchParam.settlementNo,
                lineType:searchParam.lineType,
            })
        }
        methodsFn.getTaxEnterpriseListall(localStorage.getItem("company"))
    },[])
    useEffect(() => {
        methodsFn.getTableList(form.getFieldsValue())
    },[pageNum])

    return (
            <div>
                 {
                     !isDetail
                     ?<div>
                        {myComponent.renderSearchForm()}
                        <Table 
                            scroll={{ x: 'max-content' }}
                            columns={columns} 
                            dataSource={tableData} 
                            rowKey={(record) => record.settlementNo}
                            pagination={{
                                position: ['bottomCenter'],
                                total: total,
                                current: pageNum,
                                pageSize: pageSize,
                                showSizeChanger:false,
                                showTotal:(total) =>  `共 ${total} 条数据` ,
                                onChange: (pageNum, pageSize) => setPageNum(pageNum)
                            }}
                        />
                    </div>
                    :<OnlinePayDetail1 isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn}/>
                 }
           <MyModel1 cRef={myModel1} refresh={methodsFn.refresh} waitAmount={waitAmount} sumMoney={sumMoney} settlementNo={settlementNo}/>

           <Modal
                forceRender
                title='提示框'
                width='500px'
                visible={visible2}
                footer={false}
                onCancel={() => setVisible2(false) }
                >
                <div>
                    <div>
                        <div>确权手机号 {checkObj.phone}</div>
                        <div style={{'margin': '10px 0'}}>需要进行线上支付的发放审核，审核完成后等待银行放款 </div>
                    </div>
                    <div style={{'textAlign': 'right'}}>
                        <Button type="primary"><a href={checkObj.url} target="_blank">前往操作“发放审核”</a></Button>
                    </div>
                </div>
            </Modal>
            <Modal
                forceRender
                title='提示框'
                width='500px'
                visible={visible3}
                footer={false}
                onCancel={() => setVisible3(false) }
                >
                <div>
                    当前企业未完成银行确权，请前往<a href="/admin/bankRight">"银行确权"</a>页面完成确权操作
                </div>
            </Modal>
        </div>
    )
}

export default OnlinePay
