import { request } from '../../../utils/fetch'


export const getCompanyDetail = (params) => {
    return request.get('/company/companyBase/detail', params)
}

export const deleteReceipt = (data) => {
    return request.post('/company/companyBase/deleteReceipt', data)
}

// 新增收件人
export const insertReceipt = (data) => {
    return request.post('/company/companyBase/insertReceipt', data)
}

// 修改
export const updateReceipt = (data) => {
    return request.post('/company/companyBase/updateReceipt', data)
}

export const getConfirmationUrl = (data) => {
    return request.post('/company/companyBase/getConfirmationUrl', data)
}

// 历史配置
export const getHistoryConfig = () => {
    return request.post('/company/companyBase/historyConfig')
}