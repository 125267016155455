import React, { useRef, useState, useEffect } from 'react'
import {
    Row,
    Col,
    Table,
    Select,
    Form,
    Input,
    Radio,
    Button,
    DatePicker,
    Space
} from 'antd';
import qs from 'qs'
import moment from 'moment';
import SettleQueryDetail1 from './SettleQueryDetail1'
import ConfirmModel from './ConfirmModel'
import store from '../../../../store/index'
import { request } from '../../../../utils/fetch'
import { decimalAdd } from '../../../../utils/auth'

const FormItem = Form.Item;
const { Option } = Select;
const { RangePicker } = DatePicker;
const itemStyle = {
    width: '320px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
}

function SettleQuery(props) {
    let path = "company:Settlement"
    let directFlag = localStorage.getItem('directFlag')
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g, ''));
    const [form] = Form.useForm();
    const initSearch = {
        date: [],
        taskName: ''
    }
    const ConfirmModelRef = useRef();
    const [dayValue, setDayValue] = React.useState('');
    const [tableData, setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [isDetail, setIsDetail] = useState(false)
    const [parmObj, setParmObj] = useState({})       //传给明细的参数
    const columns = [
        {
            title: '结算单生成时间',
            dataIndex: 'statusUpdateTime',
            key: 'statusUpdateTime',
            width: '190px',
            align: 'center',
        },
        {
            title: '任务单号',
            dataIndex: 'billNo',
            key: 'billNo',
            width: '170px',
            align: 'center',
        },
        {
            title: '任务名称',
            dataIndex: 'taskName',
            key: 'taskName',
            width: '190px',
            align: 'center',

        }, {
            title: '结算通道',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            width: '200px',
            align: 'center',
        },{
            title: '参与人数',
            dataIndex: 'staffCount',
            key: 'staffCount',
            width: '100px',
            align: 'center',
        }, {
            title: '任务总额（元）',
            dataIndex: 'totalCommissionAmount',
            key: 'totalCommissionAmount',
            width: '150px',
            align: 'center',
            render: (totalCommissionAmount,record)=>{
                return decimalAdd([totalCommissionAmount,record.totalServiceAmount])
            }
        },
        {
            title: '服务费（元）',
            dataIndex: 'totalServiceAmount',
            key: 'totalServiceAmount',
            width: '120px',
            align: 'center',
        },
        {
            title: '承揽费用（元）',
            dataIndex: 'totalCommissionAmount',
            key: 'totalCommissionAmount',
            width: '140px',
            align: 'center',
        },  
        {
            title: '已支付金额（元）',
            dataIndex: 'finishPaymentAmount',
            key: 'finishPaymentAmount',
            width: '150px',
            align: 'center',
        },  
        {
            title: '状态',
            dataIndex: 'paymentStatusName',
            key: 'paymentStatusName',
            width: '180px',
            align: 'center',
        },
        {
            title: '操作',
            key: 'action',
            width: '160px',
            align: 'center',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">
                    {store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1&&(record.paymentStatus=='WZF'||record.paymentStatus=='ZFSB') && directFlag == '1' && <a onClick={() => { methodsFn.goLoan(record) }}>立即放款</a>}
                    {store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1 && <a onClick={() => { methodsFn.goDetail(record) }}>查看</a>}
                </Space>
            ),
        },
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} initialValues={initSearch}>
                    <Row>
                        <FormItem label="时间" name="date" style={{ marginLeft: '40px' }} >
                            <RangePicker
                                onChange={methodsFn.timeChange}
                            />
                        </FormItem>
                        <FormItem style={{ with: '600px', marginLeft: '26px' }} label="">
                            <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                                <Radio value={1}>今天</Radio>
                                <Radio value={7}>近7天</Radio>
                                <Radio value={30}>近30天</Radio>
                            </Radio.Group>
                        </FormItem>
                    </Row>
                    <Row>
                        <FormItem label="状态" name="paymentStatusList" style={itemStyle} {...labelStyleObj}>
                            <Select placeholder="全部">
                                <Option value={'WZF'}>未支付</Option>
                                <Option value={'ZFZ'}>支付中</Option>
                                <Option value={'ZFSB'}>支付失败</Option>
                                <Option value={'WC_QBZF'}>支付完成且全部成功</Option>
                                <Option value={'WC_CW'}>支付完成,含错误数据</Option>
                            </Select>
                        </FormItem>
                        <FormItem name="taskName" label="任务名称" style={itemStyle} {...labelStyleObj} >
                            <Input placeholder='请输入' />
                        </FormItem>
                    </Row>
                    <Row>
                        <Col span={24} md={24} lg={24}>
                            <FormItem style={{ float: 'right', whiteSpace: 'nowrap', marginBottom: '0px' }} >
                                <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                                {store.getState().userReducer.permissions.indexOf(`${path}:export`) > -1 && <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            );
        },

    }
    //方法函数
    const methodsFn = {
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1, 'd').format('YYYY-MM-DD'),
                endDate = moment().format('YYYY-MM-DD');
            setDayValue(e.target.value)
            form.setFieldsValue({
                date: [moment(startDate), moment(endDate)]
            })
        },
        timeChange(dates, dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate), moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date: dateArr
            })

        },
        goLoan(record){
            ConfirmModelRef.current.showModal(record);
        },
        getsearchObj() {
            let formObj = form.getFieldsValue();
            let beginTime = '',
                endTime = '';
            if (formObj.date.length > 1) {
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            } else {
                beginTime = ''
                endTime = ''
            }
            let obj = {
                beginTime: beginTime,
                endTime: endTime,
                taskName: formObj.taskName,
                paymentStatusList: formObj.paymentStatusList,
            }
            return obj
        },
        handleSearch() {
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset() {
            setDayValue('')
            form.resetFields();
        },
        async getTableList(searchObj) {
            let result = await request.get('/company/settlement/getList', {
                ...searchObj,
                pageSize: pageSize,
                pageNum: pageNum,
            })
            setTotal(result.totalCount)
            setTableData(result.list)
        },
        goDetail(record) {
            setIsDetail(true)
            props.history.push('/admin/settleQuery?detail=' + record.settlementNo)
            setParmObj({
                ...record,
                settlementNo: record.settlementNo,
                servicePaymentTime: record.servicePaymentTime,
                paymentTime1: record.paymentTime1,
                paymentTime2: record.paymentTime2,
            })
        },
        detailFn() {
            setIsDetail(false)
            props.history.push('/admin/settleQuery')
        },
        handleExport() {
            request.downExport('/company/settlement/export', methodsFn.getsearchObj())
        }
    }

    useEffect(() => {
        if (props.location.search.indexOf('detail') > -1) {       //解决明细页面刷新问题
            methodsFn.goDetail({
                settlementNo: searchParam.detail,
            })
        }
    }, [])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    }, [pageNum])

    return (
        <div>
            {
                !isDetail ?
                    <div>
                        {myComponent.renderSearchForm()}
                        <Table
                            scroll={{ x: 'max-content' }}
                            columns={columns}
                            dataSource={tableData}
                            rowKey={(record) => record.settlementNo}
                            pagination={{
                                position: ['bottomCenter'],
                                total: total,
                                current: pageNum,
                                pageSize: pageSize,
                                showSizeChanger: false,
                                showTotal: (total) => `共 ${total} 条数据`,
                                onChange: (pageNum, pageSize) => setPageNum(pageNum)
                            }}
                        />
                    </div>
                    : <SettleQueryDetail1 isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn} />
            }
            <ConfirmModel refresh={methodsFn.handleSearch} ref={ConfirmModelRef} />
        </div>
    )
}

export default SettleQuery
