let baseURL; 
let imgUrl = '';
if(process.env.NODE_ENV === 'development'){
  // baseURL = 'http://rap2api.taobao.org/app/mock/274053';        //开发环境
  // baseURL = 'http://192.168.19.10:8700';  // 光松  
  // baseURL = 'http://192.168.19.94:8700';  // 徐卉
  // baseURL = 'http://192.168.19.100:8700'; // 凯奇
  // baseURL = 'https://hire.wispay.cn/hire';    
  baseURL = 'http://test.zchpay.com/hire';    
}else{ 
  baseURL = '';         //生产环境
}

export {imgUrl, baseURL}