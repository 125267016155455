// 线上明细
import React,{useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Button,
  Modal,
  Space,
  PageHeader
 } from 'antd';
import { withRouter } from "react-router-dom";
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'
const { Option } = Select;
const { TextArea } = Input;
 const FormItem = Form.Item;
 const topCol = {
    span:8,
  }
  const endCol = {
    span:8,
    offset:6
  }
 const itemStyle = {
     width:'300px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 9},
    wrapperCol:{ span: 15}
 }

function CheckQueryDetail(props) {
    let path = 'company:Audit'
    const [form] = Form.useForm();
    const {parmObj} = props
    const initSearch = {
        keyword:'',
    }
    const [tableData,setTableData] = useState([])
    const [reasonList,setReasonList] = useState([])
    const [visible, setVisible] = React.useState(false);
    const [detailObj, setDetailObj] = React.useState({});
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
     
    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align:'center',
            
        }, {
            title: '打款备注',
            dataIndex: 'content',
            key: 'content',
            width: 150,
            align:'center',
            
        }, 
        {
            title: '身份证号',
            dataIndex: 'idNumber',
            key: 'idNumber',
            width: 150,
            align:'center',
            
        },  {
            title: '佣金',
            dataIndex: 'commissionAmount',
            key: 'commissionAmount',
            width: 150,
            align:'center',
            
        },{
            title: '进入审核的原因',
            dataIndex: 'auditReasonName',
            key: 'auditReasonName',
            width: 150,
            align:'center',
            
        }, {
            title: '审核状态',
            dataIndex: 'auditStatusName',
            key: 'auditStatusName',
            width: 150,
            align:'center',
        }, 
        {
            title: '操作',
            key: 'action',
            width: 100,
            align:'center',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">
                    {store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a onClick={() => methodsFn.getDetail(record)}>查看</a>}
                </Space>
                ),
            },
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} >
                    <Row>
                        <FormItem label="进入审核原因" name="auditReason"  style={itemStyle} {...labelStyleObj}>
                            <Select>
                                {
                                    reasonList.map(item => {
                                       return <Option key={item.id} value={item.id}>{item.reason}</Option>
                                    })
                                }
                            </Select>
                        </FormItem>
                        <FormItem label="审核状态" name="auditStatus"  style={itemStyle} {...labelStyleObj}>
                            <Select>
                                <Option value={'SHZ'}>审核中</Option>
                                <Option value={'SHJJ'}>审核拒绝</Option>
                                <Option value={'SHTG'}>审核通过</Option>
                                {/* <Option value={'ZDTG'}>自动审核通过</Option> */}
                            </Select>
                        </FormItem>
                        <FormItem label="关键字" name="keyword"  style={itemStyle} {...labelStyleObj}>
                            <Input placeholder="姓名丨身份证号" />
                        </FormItem>
                        <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                            <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                            <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                            {store.getState().userReducer.permissions.indexOf(`${path}:exportDetail`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                        </FormItem>
                    </Row>
                </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        getsearchObj(){
            return (
                {
                    auditReasonList:form.getFieldsValue().auditReason,
                    auditStatus:form.getFieldsValue().auditStatus,
                    keyword:form.getFieldsValue().keyword,
                    auditNo:parmObj.auditNo,
                }
            )
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/company/audit/getDetailList',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        async getAuditReason(){
            let result = await request.get('/common/getAuditReason')
            let arr = []
            for( var key in result){
                arr.push({
                    id:key,
                    reason:result[key]
                })
            }
            setReasonList(arr)
        },
        async getDetail(record){
            let result = await request.get('/company/audit/getDetail',{
                auditNo:record.auditNo,
                billDetailNo:record.billDetailNo
            })
            setDetailObj(result)
            setVisible(true)
          
        },
        handleExport(){
            request.downExport('/company/audit/exportDetail',methodsFn.getsearchObj())
        }
   
    }
    useEffect(() => {
        methodsFn.getAuditReason()
    },[])

    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={props.callFn}
                title="明细"
                subTitle=""
            />

            {myComponent.renderSearchForm()}
           <Table 
            scroll={{ x: 'max-content' }}
            columns={columns} 
            dataSource={tableData} 
            rowKey={(record) => record.idNumber}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
            }}
           />
            <Modal
                title='查看'
                visible={visible}
                width='1000px'
                footer={false}
                onCancel={() => setVisible(false)}>
                <Row className="margin-15">
                    <Col {...topCol}>
                    <div className="flex">
                        <span className="left-span">进入审核时间:</span>
                        <Input placeholder={detailObj.createTime} disabled={true} />
                    </div>
                    </Col>
                    <Col {...endCol}>
                    <div className="flex">
                        <span className="left-span">落地城市:</span>
                        <Input placeholder={detailObj.cityName} disabled={true} />
                    </div>
                    </Col>
                </Row>
                <Row className="margin-15">
                    <Col {...topCol}>
                    <div className="flex">
                        <span className="left-span">企业名称:</span>
                        <Input placeholder={detailObj.companyCodeName} disabled={true} />
                    </div>
                    </Col>
                    <Col {...endCol}>
                    <div className="flex">
                        <span className="left-span">落地方案:</span>
                        <Input placeholder={detailObj.planTypeName}  disabled={true} />
                    </div>
                    </Col>
                </Row>
                <Row className="margin-15">
                    <Col {...topCol}>
                    <div className="flex">
                        <span className="left-span">账单月份:</span>
                        <Input placeholder={detailObj.month} disabled={true} />
                    </div>
                    </Col>
                    <Col {...endCol}>
                    <div className="flex">
                        <span className="left-span">佣金账单ID:</span>
                        <Input placeholder={detailObj.billNo} disabled={true} />
                    </div>
                    </Col>
                </Row>
                <Row className="margin-15">
                    <Col {...topCol}>
                    <div className="flex">
                        <span className="left-span">审核账单ID:</span>
                        <Input placeholder={detailObj.auditNo} disabled={true} />
                    </div>
                    </Col>
                    <Col {...endCol}>
                    <div className="flex">
                        <span className="left-span">姓名:</span>
                        <Input placeholder={detailObj.name} disabled={true} />
                    </div>
                    </Col>
                </Row>
                <Row className="margin-15">
                    <Col {...topCol}>
                    <div className="flex">
                        <span className="left-span">身份证号:</span>
                        <Input placeholder={detailObj.idNumber} disabled={true} />
                    </div>
                    </Col>
                    <Col {...endCol}>
                    <div className="flex">
                        <span className="left-span">佣金:</span>
                        <Input placeholder={detailObj.commissionAmount} disabled={true} />
                    </div>
                    </Col>
                </Row>
                <Row className="margin-15">
                    <Col {...topCol}>
                    <div className="flex">
                        <span className="left-span">税费:</span>
                        <Input placeholder={detailObj.taxAmount} disabled={true} />
                    </div>
                    </Col>
                    <Col {...endCol}>
                    <div className="flex">
                        <span className="left-span">服务费:</span>
                        <Input placeholder={detailObj.serviceAmount} disabled={true} />
                    </div>
                    </Col>
                </Row>

                <Row className="margin-15">
                    <Col {...topCol}>
                    <div className="flex">
                        <span className="left-span">进入审核的原因:</span>
                        <Input placeholder={detailObj.auditReasonName} disabled={true} />
                    </div>
                    </Col>
                    <Col {...endCol}>
                    <div className="flex">
                        <span className="left-span">审核状态:</span>
                        <Input placeholder={detailObj.auditStatusName} disabled={true} />
                    </div>
                    </Col>
                </Row>
                <Row className="margin-15">
                        <div className="flex">
                            <span className="left-span" style={{'marginLeft': '-87px'}}>打款备注</span>
                            <TextArea style={{'width': '750px'}} cols="100" disabled={true} placeholder={detailObj.content}/>
                        </div>
                </Row>
                <h2>审核操作日志</h2>
                <Row className="margin-15">
                    <Col {...topCol}>
                    <div className="flex">
                        <span className="left-span">操作时间:</span>
                        <Input placeholder={detailObj.auditTime} disabled={true} />
                    </div>
                    </Col>
                    <Col {...endCol}>
                    <div className="flex">
                        <span className="left-span">操作人:</span>
                        <Input placeholder={detailObj.auditUser} disabled={true} />
                    </div>
                    </Col>
                </Row>
                <Row className="margin-15">
                    <Col {...topCol}>
                    <div className="flex">
                        <span className="left-span">操作类型:</span>
                        <Input placeholder={detailObj.auditStatusName} disabled={true} />
                    </div>
                    </Col>
                    <Col {...endCol}>
                    <div className="flex">
                        <span className="left-span">补缴服务费:</span>
                        <Input placeholder={detailObj.addServiceAmount} disabled={true} />
                    </div>
                    </Col>
                </Row>
                <Row className="margin-15">
                    <div className="flex">
                        <span className="left-span" style={{'marginLeft': '-87px'}}>审核描述:</span>
                        <TextArea style={{'width': '750px'}} cols="100" disabled={true} placeholder={detailObj.auditContent}/>
                    </div>
                </Row>
            </Modal>

        </div>
    )
}

export default withRouter(CheckQueryDetail)
