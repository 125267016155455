// 线上明细
import React,{useState,useEffect} from 'react'
import {
  Row,
  Table,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  PageHeader,
  Typography
 } from 'antd';
 import qs from 'qs'
import { withRouter } from "react-router-dom";
import {request} from '../../../../utils/fetch'
import moment from 'moment';
import store from '../../../../store/index'
 const FormItem = Form.Item;
 const { Paragraph } = Typography;
 const { RangePicker } = DatePicker;
 const itemStyle = {
     width:'330px',
     marginRight:'15px'
}
const { Option } = Select
 const labelStyleObj = {
    labelCol:{ flex: '90px'},
    wrapperCol:{ flex: '1'}
 }

function DetailPay(props) {
    let path = 'company:OnlinePayment'
    const [form] = Form.useForm();
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g, ''));
    const [tableData,setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
     
    const columns = [
       {
            title: '企业名称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: '260',
            align:'left',
            render: (companyName, record) => {
                return (
                    <>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            {companyName}
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <Paragraph copyable>{record.rechargeNo}</Paragraph>
                        </div>
                    </>
                )
            }
        },
        {
            title: '结算通道',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            width: 260,
            align:'left',
            
        },
        {
            title: '汇款信息',
            dataIndex: 'bankNumber',
            key: 'bankNumber',
            width: 300,
            align:'left',
            render: (text, record) => {
                return (
                    <>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'50px',textAlign: 'right',marginRight: '10px'}} >开户名:</div>
                            <div style={{flex: '1',textAlign: 'left'}} >{record.bankAccountName}</div>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'50px',textAlign: 'right',marginRight: '10px'}} >账号:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.bankAccountNo}</div>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'50px',textAlign: 'right',marginRight: '10px'}} >银行:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.bankName}</div>
                        </div>
                    </>
                )
            }
        },
        {
            title: '金额信息（元）',
            dataIndex: 'commissionAmount',
            key: 'commissionAmount',
            width: 150,
            align:'left',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'100px',textAlign: 'right',marginRight: '10px'}} >申请充值金额:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.amount}</div>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'100px',textAlign: 'right',marginRight: '10px'}} >确认充值金额:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.sureAmount}</div>
                        </div>
                    </div>
                )
            }
            
        },{
            title: '时间信息',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 250,
            align:'left',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'60px',textAlign: 'right',marginRight: '10px'}} >申请时间:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.createTime}</div>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'60px',textAlign: 'right',marginRight: '10px'}} >确认时间:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.sureTime}</div>
                        </div>
                    </div>
                )
            }
        },  
        {
            title: '状态',
            dataIndex: 'statusName',
            key: 'statusName',
            width: 100,
            align:'center'
        }
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} >
                    <Row>
                        <FormItem label="汇款识别码" name="keyword"  style={itemStyle} {...labelStyleObj}>
                            <Input placeholder="请输入" />
                        </FormItem>
                        <Form.Item label="支付时间" name="date" style={itemStyle} {...labelStyleObj} >
                            <RangePicker />
                        </Form.Item>
                        <FormItem label="充值状态" name="statusOrTypeList"  style={itemStyle} {...labelStyleObj}>
                            <Select placeholder="全部">
                                <Option value={1}>确认成功</Option>
                                <Option value={2}>确认失败</Option>
                            </Select>
                        </FormItem>
                        <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                            <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                            <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                            {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                        </FormItem>
                    </Row>
                   
                </Form>
            );
        },
    }
    //方法函数
    const methodsFn = {
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let obj = {
                statusOrTypeList: formObj.statusOrTypeList,
                taxEnterpriseCode: searchParam.taxEnterpriseCode,
                keyword: formObj.keyword,
            }

            if(formObj.date&&formObj.date.length>0){
                obj.beginTime = moment(formObj.date[0]).format('YYYY-MM-DD')
                obj.endTime = moment(formObj.date[1]).format('YYYY-MM-DD')
            }
            return obj
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/company/companyAccount/getRechargeList',{
                ...searchObj,
            })
            setTotal(result.totalCount)
            setTableData(result.list)
        },
        handleExport(){
            request.downExport('/company/companyAccount/exportRechargeList',methodsFn.getsearchObj())
        }
    }

    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={() => {props.history.goBack()}}
                title="充值记录"
                subTitle=""
            />

            {myComponent.renderSearchForm()}
           <Table 
            scroll={{ x: 'max-content' }}
            columns={columns} 
            dataSource={tableData} 
            rowKey={(record) => record.id}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
            }}
           />

         
        </div>
    )
}

export default withRouter(DetailPay)
