// 线上明细
import React,{useState,useEffect} from 'react'
import {
  Row,
  Table,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  PageHeader
 } from 'antd';
 import qs from 'qs'
import { withRouter } from "react-router-dom";
import {request} from '../../../../utils/fetch'
import moment from 'moment';
import store from '../../../../store/index'
 const FormItem = Form.Item;
 const { RangePicker } = DatePicker;
 const itemStyle = {
     width:'330px',
     marginRight:'15px'
}
const { Option } = Select
 const labelStyleObj = {
    labelCol:{ flex: '90px'},
    wrapperCol:{ flex: '1'}
 }

function Detail(props) {
    let path = 'company:OnlinePayment'
    const [form] = Form.useForm();
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g, ''));
    const [tableData,setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
     
    const columns = [
        {
            title: '交易单号',
            dataIndex: 'dealNo',
            key: 'dealNo',
            width: 160,
            align:'center',
            
        }, {
            title: '账户名称',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            width: 260,
            align:'center',
            
        },
        {
            title: '交易类型',
            dataIndex: 'dealTypeName',
            key: 'dealTypeName',
            width: 150,
            align:'center',
            
        },
        {
            title: '交易金额(元)',
            dataIndex: 'dealAmount',
            key: 'dealAmount',
            width: 150,
            align:'center',
            
        },
        {
            title: '交易时间',
            dataIndex: 'dealTime',
            key: 'dealTime',
            width: 170,
            align:'center',
            
        },
        {
            title: '交易前金额（元）',
            dataIndex: 'beforeBalance',
            key: 'beforeBalance',
            width: 150,
            align:'center',
            
        },{
            title: '交易后金额（元）',
            dataIndex: 'afterBalance',
            key: 'afterBalance',
            width: 150,
            align:'center',
            
        },  
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: 150,
            align:'center',
        },
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} >
                    <Row>
                    <Form.Item labelCol={{ flex: '100px' }} name="statusOrTypeList" style={{width: '330px'}} label="交易类型">
                        <Select placeholder="全部" allowClear >
                        <Option value={'1'}>充值</Option>
                        <Option value={'2'}>上账</Option>
                        <Option value={'3'}>下账</Option>
                        <Option value={'4'}>任务支付</Option>
                        <Option value={'5'}>任务退回</Option>
                        </Select>
                    </Form.Item>
                        <Form.Item label="交易时间" name="date" style={itemStyle} {...labelStyleObj} >
                            <RangePicker />
                        </Form.Item>
                        <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                            <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                            <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                            {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                        </FormItem>
                    </Row>
                   
                </Form>
            );
        },

    }
    //方法函数
    const methodsFn = {
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let obj = {
                keyword: formObj.keyword,
                statusOrTypeList: formObj.statusOrTypeList,
                taxEnterpriseCode: searchParam.taxEnterpriseCode,
            }

            if(formObj.date&&formObj.date.length>0){
                obj.beginTime = moment(formObj.date[0]).format('YYYY-MM-DD')
                obj.endTime = moment(formObj.date[1]).format('YYYY-MM-DD')
            }
            return obj
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/company/companyAccount/getDealList',{
                ...searchObj,
            })
            setTotal(result.totalCount)
            setTableData(result.list)
        },
        handleExport(){
            request.downExport('/company/companyAccount/exportDealList',methodsFn.getsearchObj())
        }
    }

    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={() => {props.history.goBack()}}
                title="交易明细"
                subTitle=""
            />

            {myComponent.renderSearchForm()}
           <Table 
            scroll={{ x: 'max-content' }}
            columns={columns} 
            dataSource={tableData} 
            rowKey={(record) => record.id}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
            }}
           />

         
        </div>
    )
}

export default withRouter(Detail)
