import React,{useRef,useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Radio,
  Button,
  message,
  DatePicker,
  Upload,
  Popconfirm,
  Modal,
  Space
 } from 'antd';
import axios from 'axios'
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'
const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

function ContractInfo(props) {
    let path ='company:CompanySendConfig'
    const [form] = Form.useForm();
    const [disabled, setDisabled] = React.useState(true);
    const [companyCode, setCompanyCode] = React.useState('');
    
   
     const getDetail = async () => {
           const result = await request.get('/company/companySendConfig/getDetail')
            form.setFieldsValue(result)
            setCompanyCode(result.companyCode)
    }
    const handleChange = () => {
        setDisabled(true)
        request.post('/company/companySendConfig/update',Object.assign(form.getFieldsValue(),{
            companyCode:companyCode
        })).then(res => {
            // getDetail()
            message.success(res.msg)
        })
 }


    useEffect(() => {
        getDetail()
    
    },[])

    return (
        <div>
            <div style={{ 'padding': '40px 100px','background': '#fff'}} >
                <Form form={form}　initialValues={{
                    companyName:''
                }}>
                    <FormItem label="发包企业" name="companyName">
                        <Input disabled></Input>
                    </FormItem>
                    <FormItem label="任务类型" name="taskType">
                        <Select placeholder="请选择" disabled={disabled}>
                            <Option value={1}>配送服务</Option>
                            <Option value={2}>交通运输服务</Option>
                            <Option value={3}>广播影视服务</Option>
                            <Option value={4}>收派服务</Option>
                            <Option value={5}>居民日常服务</Option>
                            <Option value={6}>技术咨询服务</Option>
                            <Option value={7}>销售推广服务</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="服务项目" name="serviceProject">
                        <TextArea disabled={disabled}></TextArea>
                    </FormItem>
                    <FormItem label="接单条件" name="orderCondition">
                        <TextArea disabled={disabled}></TextArea>
                    </FormItem>
                    <FormItem label="结算方式" name="orderWay">
                        <TextArea disabled={disabled}></TextArea>
                    </FormItem>
                    <FormItem label="考核标准" name="assessStandard">
                        <TextArea disabled={disabled}></TextArea>
                    </FormItem>
            </Form>
           
            {
               store.getState().userReducer.permissions.indexOf(`${path}:update`)>-1&&( disabled
                ?<Row style={{'display': 'flex','justifyContent': 'center'}}>
                    <Button type="primary" onClick={() => {
                        setDisabled(false)
                    }}>修改</Button>
                </Row>
                :<Row style={{'display': 'flex','justifyContent': 'center'}}>
                    <Button type="primary" onClick={handleChange}>保存</Button>
                </Row>)
            }
          
                
           
            </div>
            
        </div>
    )
}

export default ContractInfo
