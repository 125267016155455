import React,{useState,useEffect,useRef} from 'react'
import {
  Row,
  Table,
  Select,
  Form,
  Input,
  Button,
  Col,
  Space,
  Popconfirm,
  PageHeader,
  message,
 } from 'antd';
import qs from 'qs'
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'
import MyModel1 from './MyModel';
const { Option } = Select;
 const FormItem = Form.Item;
 const itemStyle = {
     width:'300px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ flex: '80px'},
    wrapperCol:{ flex: 1}
 }

function CityStore(props) {
    let path = 'company:User'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
    const [form] = Form.useForm();
    const myModel1 = useRef();
    const initSearch = {
        keyword: '',
        status: null
    }
    const [tableData,setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [titleModel,setTitleModel] = useState('新增')
     
    const columns = [
        {
            title: '城市',
            dataIndex: 'cityName',
            key: 'cityName',
            align:'center',
        }, {
            title: '门店',
            dataIndex: 'storeName',
            key: 'storeName',
            align:'center',
        }, {
            title: '门店ID',
            dataIndex: 'storeId',
            key: 'storeId',
            align:'center',
        }, {
            title: '品牌',
            dataIndex: 'brandName',
            key: 'brandName',
            align:'center',
        },  {
            title: '业务',
            dataIndex: 'business',
            key: 'business',
            align:'center',
        }, {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align:'center',
            render: (text, record) => record.status ?'启用':'停用'
        },{
            title: '操作',
            key: 'action',
            align:'center',
            width: 120,
            render: (text, record) => (
                <Space size="middle">
                    {<Popconfirm
                        title={`确定${record.status?'停用':'启用'}吗？`}
                        onConfirm={() => {
                            methodsFn.handleStatus(record)
                        }}
                        okText="确定"
                        cancelText="取消"
                        className="marginLeft"
                        >
                        <Button type="link">{record.status?'停用':'启用'}</Button>
                    </Popconfirm>}
                    {<Popconfirm
                        title={`确定删除吗？`}
                        onConfirm={() => {
                            methodsFn.handleDelete(record)
                        }}
                        okText="确定"
                        cancelText="取消"
                        className="marginLeft"
                        >
                        <Button type="link">删除</Button>
                    </Popconfirm>}
                </Space>
            ),
        },
     
    ];
   
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} initialValues={initSearch}>
                    <Row>
                        <FormItem label="状态" name="status" style={itemStyle} {...labelStyleObj}>
                            <Select placeholder="全部">
                                <Option value={0}>停用</Option>
                                <Option value={1}>启用</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="门店名称" name="keyword" style={itemStyle} {...labelStyleObj}>
                            <Input  placeholder="请输入" />
                        </FormItem>
                    </Row>
                    <Row>
                        <Col span={24} md={24} lg={24}>
                            <FormItem style={{ float: 'right', whiteSpace: 'nowrap', marginBottom: '0px' }} >
                                <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                                {<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleAdd}>导入</Button>}
                                {<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(form.getFieldsValue())
        },
        handleFormReset(){
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/company/cityStore/list',Object.assign(searchObj,
                {
                    pageSize:pageSize,
                    pageNum:pageNum,
                }
            ))
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        handleAdd(){
            setTitleModel('新增')
            myModel1.current.showModal()
        },
        handleExport(){
            let exportObj = form.getFieldsValue()
            request.downExport('/company/cityStore/export',
                { 
                    ...exportObj,
                }
            )
        },
        handleStatus(obj){
            let status;
            if(obj.status){
                status = 0
            }else{
                status = 1
            }
            request.post('/company/cityStore/updateStatus',{
                storeId:obj.storeId,
                status:status,
            }).then(res => {
                methodsFn.getTableList(form.getFieldsValue())
                message.success('操作成功')
            })
        },
        handleDelete(obj){
            request.post('/company/cityStore/delete',{
                storeId:obj.storeId,
            }).then(res => {
                methodsFn.getTableList(form.getFieldsValue())
                message.success('删除成功')
            })
        },
        refresh(){
            methodsFn.getTableList(form.getFieldsValue())
        },
    }
    useEffect(() => {
        methodsFn.getTableList(form.getFieldsValue())
    },[pageNum])

    return (
        <div>
             {
               searchParam.detail
                    ?<PageHeader
                    className="site-page-header"
                    onBack={() => {props.history.goBack()}}
                    title="明细"
                    subTitle={'公司名称：'+searchParam.companyName}
                />
                :null
            }

            {myComponent.renderSearchForm()}
           <Table 
            scroll={{ x: 'max-content' }}
            columns={columns} 
            rowKey={(record) => record.id}
            dataSource={tableData} 
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
            }}
           />
           <MyModel1 ref={myModel1} titleModel={titleModel} refresh={methodsFn.refresh}/>
        </div>
    )
}

export default CityStore
