import React,{useState,useEffect,useRef} from 'react'
import { Button, Popover, BackTop } from 'antd';
import '../dashboard.css'
// import MyParticles from '../components/MyParticles'
import logo from '../images/logo1.png'
import secheme from '../images/secheme.png'
import wxCode from '../images/wxCode.jpg'

import yw1 from '../images/yw1.png'
import yw2 from '../images/yw2.png'
import yw3 from '../images/yw3.png'
import yw4 from '../images/yw4.png'
import yw5 from '../images/yw5.png'
import yw6 from '../images/yw6.png'
import yw7 from '../images/yw7.png'
import yw8 from '../images/yw8.png'

import cp1 from '../images/cp1.png'
import cp2 from '../images/cp2.png'
import cp3 from '../images/cp3.png'
import cp4 from '../images/cp4.png'

import { PhoneFilled, QrcodeOutlined, UpOutlined } from '@ant-design/icons';


const lianxi = () => {
  return (
    <div>
      <div>联系我们</div>
      <div>15632069760</div>
    </div>
  )
}

const wxcode = () => {
  return (
    <img style={{width: '120px', height: '120px'}} src={wxCode} alt="快速访问小程序" />
  )
}

const goTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // 可选，平滑滚动效果
  });
}

const Dashboard = (props) => {

  const goLogin = () =>{
    props.history.push('/login')
  }

  return (
    <>
    <div className="dashboard">
      <div className='dashboard__container' >
        <div className='header-box'>
          <div className='dashboard__header' >
            <div className="headerlogo"></div>
            <div>
              <Button className='btn1' onClick={goLogin} >工作台</Button>
            </div>
          </div>
        </div>
        <div className='dashboard__body' >
          <img className='logoimg' src={logo} alt="" />
          <div className='content_title' >互联网用工平台</div>
          <div className='content_des' >以共享服务产品+工具为核心，为企业提供服务匹配、项目管理、合规结算等全链路服务</div>
          <div className='content_des' >同时聚焦人资公司核心业务场景、企业的现实需求打造数字化管理工具</div>
          <div className='btn3' onClick={goLogin} >立即使用</div>
        </div>
      </div>
      <div className='dashboard-common' >
        <div className='common-title' >解决方案</div>
        <div className='common-des' >根据不同行业及企业特征，针对性提供专属完税结算方案</div>
        <div className='secheme-bg' style={{gap: '30px'}} >
          <div className='secheme-bg' >
            <div className='secheme-label secheme-label1' ></div>
            <div className='secheme-content' >业务流</div>
          </div>
          <div className='secheme-bg' >
            <div className='secheme-label secheme-label2' ></div>
            <div className='secheme-content' >合同凭证流</div>
          </div>
          <div className='secheme-bg' >
            <div className='secheme-label secheme-label3' ></div>
            <div className='secheme-content' >发票流</div>
          </div>
        </div>
        <div className='secheme-img' >
          <img src={secheme} alt="" />
        </div>

        <div className='common-title' >主要业务场景</div>
        <div className='common-des' >探索更广阔的行业蓝海</div>
        <div className='yw-box' >
          <div className='ywline'>
            <div className='yw-item' >
              <img src={yw1} alt="" />
              <div className='yw-title' >生活管家</div>
              <div className='yw-des' >Housekeeper</div>
            </div>
            <div className='yw-item' >
              <img src={yw2} alt="" />
              <div className='yw-title' >维保服务者</div>
              <div className='yw-des' >Maintenance service provider</div>
            </div>
            <div className='yw-item' >
              <img src={yw3} alt="" />
              <div className='yw-title' >视频创作者</div>
              <div className='yw-des' >Video creator</div>
            </div>
            <div className='yw-item' >
              <img src={yw4} alt="" />
              <div className='yw-title' >市场拓展员</div>
              <div className='yw-des' >Market expansion personnel</div>
            </div>
          </div>

          <div className='ywline'>
            <div className='yw-item' >
              <img src={yw5} alt="" />
              <div className='yw-title' >知识课讲师</div>
              <div className='yw-des' >Lecturer of knowledge course</div>
            </div>
            <div className='yw-item' >
              <img src={yw6} alt="" />
              <div className='yw-title' >自由摄影师</div>
              <div className='yw-des' >Freelance photographer</div>
            </div>
            <div className='yw-item' >
              <img src={yw7} alt="" />
              <div className='yw-title' >仓储管理员</div>
              <div className='yw-des' >Warehouse keeper</div>
            </div>
            <div className='yw-item' >
              <img src={yw8} alt="" />
              <div className='yw-title' >自由行管家</div>
              <div className='yw-des' >Freelance housekeeper</div>
            </div>
          </div>
        </div>
      </div>

      <div className='cps-box' >
        <div className='cps-title'>我们的产品优势</div>
        <div className='cps-des' >加速您企业的发展</div>
        <div className='yw-box' >
          <div className='ywline'>
            <div className='yw-item' >
              <img src={cp1} alt="" />
              <div className='yw-title' >共享产品更丰富</div>
              <div className='yw-des' >强大的供应链整合能力，为企业提供如设计、营销推广、研发等千余种品类的共享产品，满足企业跨地域、跨行业等需求</div>
            </div>
            <div className='yw-item' >
              <img src={cp2} alt="" />
              <div className='yw-title' >创新技术更灵活</div>
              <div className='yw-des' >拥有系统化工具能力，深入企业需求场景，为企业客户与自由职业者、人资公司等提供多样化的工具与服务</div>
            </div>
          </div>
          <div className='ywline'>
            <div className='yw-item' >
              <img src={cp3} alt="" />
              <div className='yw-title' >数字化流程更合规</div>
              <div className='yw-des' >高效的运营保障能力，提供需求匹配、项目管理、合规结算等服务并全流程线上鉴证，保障过程可追踪，建立企业的合规屏障</div>
            </div>
            <div className='yw-item' >
              <img src={cp4} alt="" />
              <div className='yw-title' >多行业经验更靠谱</div>
              <div className='yw-des' >深耕行业多年，累计服务数万家企业客户，覆盖设计、保险、科技IT、广告等干余种行业，垂直领域更专业，经验更丰富</div>
            </div>
          </div>
        </div>
      </div>
      <div style={{textAlign: 'center'}} >
        <a style={{ color: '#000' }} target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">赣ICP备19013934号-5</a>
      </div>
      {/* <MyParticles /> */}
    </div>
    <div className='index-affix' >
      <Popover placement="left" content={lianxi} >
        <div className='popovericon' >
          <PhoneFilled />
        </div>
      </Popover>
      <Popover placement="left" content={wxcode} >
        <div className='popovericon' >
          <QrcodeOutlined />
        </div>
      </Popover>
      <div className='popovericon' onClick={goTop} >
        <UpOutlined />
      </div>
    </div>
    </>
  );
}
 
export default Dashboard;