import React, { useState, useEffect } from 'react'
import {
  PageHeader,
  Image,
  Divider,
  Button,
  Popconfirm,
  Table,
  Form,
  Skeleton,
  message,
  Input,
  Space,
  Modal
} from 'antd'

import './CheckDetail.css'
import { getCompanyDetail, deleteReceipt, insertReceipt, updateReceipt, editRightTel, getConfirmationUrl } from './api'
import store from '../../../store/index'

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
}
const CompanyInfoPage = (props) => {
  let path = 'company:CompanyBase'
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState({})
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [receiptNo, setReceiptNo] = useState()
  const [type, setType] = useState()
  const [configVoList, setConfigVoList] = useState([])

  const params = props.match.params
  const modalForm = {
    receiptPhone: '',
    receiptName: '',
    receiptAddress: ''
  }

  useEffect(() => {
    getDetail()
  }, [])
  // 获取详情
  const getDetail = async () => {
    try {
      setLoading(true)
      const result = await getCompanyDetail({ companyCode: params.id })
      console.log('1111', result, params.id)
      if (result) {
        setDetail(result)
        let list = result.serviceConfigVoList.map(e=>e.planType)
        setConfigVoList(list)
      }
      console.log('res', result)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log('__企业详情', err)
    }

  }
  const refresh = () => {
    getDetail()
  }
  const goPage = () => {
    const data = {
      companyName: detail.companyName
    }
    props.history.push({ pathname: `/admin/historyConfig/${detail.companyCode}`, state: data })
  }
  const onCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleOk = async () => {
    const data = {
      receiptPhone: form.getFieldsValue().receiptPhone,
      receiptName: form.getFieldsValue().receiptName,
      receiptAddress: form.getFieldsValue().receiptAddress
    }
    try {
      await form.validateFields()
      let res
      setConfirmLoading(true)
      if (type === 'insert') {
        res = await insertReceipt(data)
      }
      if (type === 'modify') {
        res = await updateReceipt(Object.assign({}, data, { receiptNo: receiptNo }))
      }
      if (res) {
        setVisible(false)
        refresh()
      }
      setConfirmLoading(false)
    } catch (err) {

    }
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="查看"
        onBack={() => props.history.goBack()}
      />

      <div className="mes-content">
        <Skeleton loading={loading} active>
          <div style={{ position: 'relative' }}>
            <div className="mes-box">
              <div className="box-top">
                <div className="title">基本信息</div>
              </div>
              <div className="box-row">
                <span className="row-label">企业名称</span>
                <span>{detail.companyName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">项目名称</span>
                <span>{detail.projectName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">行业分类</span>
                <span>{detail.industryTypeName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">纳税人类型</span>
                <span>{detail.taxpayerTypeName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">统一社会信用代码</span>
                <span>{detail.taxpayerNumber}</span>
              </div>
              <div className="box-row">
                <span className="row-label">单位地址</span>
                <span>{detail.address}</span>
              </div>
              <div className="box-row">
                <span className="row-label">注册电话</span>
                <span>{detail.registeredPhone}</span>
              </div>
              <div className="box-row">
                <span className="row-label">开户行名称</span>
                <span>{detail.bankName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">银行账号</span>
                <span>{detail.bankNumber}</span>
              </div>
              <div className="box-row">
                <span className="row-label">账号名称</span>
                <span>{detail.bankAccountName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">法人姓名</span>
                <span>{detail.legalPersonName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">联系邮箱</span>
                <span>{detail.contactEmail}</span>
              </div>
              <div className="box-row">
                <span className="row-label">营业执照</span>
                <span>
                  {
                    detail.licenseFileUrl &&
                    <Image
                      width={100}
                      src={detail.licenseFileUrl}
                    />
                  }

                </span>
              </div>
              <div className="box-row">
                <span className="row-label">附件</span>
                <span>
                  {
                    detail.fileUrlStr && detail.fileUrlStr.split(',').map((item) => {
                      return (
                        <div>
                          <a href={item} target="_blank" >{item}</a>
                        </div>
                      )
                    })
                  }
                </span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', right: '30px', top: '60px' }}>
                {configVoList.includes(2) && 
                  <div style={{ width: '220px' }}>
                    <Image width={200} src={detail.unitPictureUrl} />
                    <a href={detail.unitPictureUrl} target="_blank" download={detail.unitPictureUrl} style={{ display: 'block', textAlign: 'center' }}>下载二维码</a>
                  </div>
                }
                {configVoList.includes(1) &&
                  <div style={{ width: '220px' }}>
                    <Image width={200} src={detail.taxPictureUrl} />
                    <a href={detail.taxPictureUrl} target="_blank" download={detail.taxPictureUrl} style={{ display: 'block', textAlign: 'center' }}>下载二维码</a>
                  </div>
                }
                {configVoList.includes(3) &&
                  <div style={{ width: '220px' }}>
                    <Image width={200} src={detail.entrustPictureUrl} />
                    <a href={detail.entrustPictureUrl} target="_blank" download={detail.entrustPictureUrl} style={{ display: 'block', textAlign: 'center' }}>下载二维码</a>
                  </div>
                }
              </div>
            </div>
          </div>
          <Divider />
          <div className="mes-box">
            <div className="box-top">
              <div className="title">
                落地配置　({detail.serviceConfigVoList && detail.serviceConfigVoList.length > 0 && detail.serviceConfigVoList[0].effectTime + '开始生效'})
              </div>
              {store.getState().userReducer.permissions.indexOf(`${path}:detail`) > -1 && <a onClick={goPage}>历史配置信息</a>}
            </div>
            {detail.serviceConfigVoList && detail.serviceConfigVoList.length > 0 && detail.serviceConfigVoList.map(item => (
              <div key={item.planType}>
                {item.planType === 3 && <div className="sub-title">委托代征</div>}
                <div className="box-row">
                  <span className="row-label">常规服务费</span>
                  <p>每笔{item.serviceValue}%</p>
                </div>
                <div className="box-row">
                  <span className="row-label">是否发送充值确认结果短信</span>
                  <div>
                    <p>{item.sendConfirmFlag?'是':'否'}</p>
                  </div>
                </div>
                <div className="box-row">
                  <span className="row-label">是否直发</span>
                  <div>
                    <p>{item.directFlag?'是':'否'}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Divider />
          <div className="mes-box">
            <div className="box-top">
              <div className="title">企业财务管理员</div>
            </div>
            <div className="box-row">
              <span className="row-label">姓名</span>
              <span>{detail.manageName}</span>
            </div>
            <div className="box-row">
              <span className="row-label">手机号</span>
              <span>{detail.managePhone}</span>
            </div>
          </div>
          <Divider />
        </Skeleton>
      </div>

      <Modal
        title="收件信息"
        visible={visible}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        onOk={handleOk}
        width={740}>
        <Form form={form} initialValues={modalForm}>
          <Form.Item {...formItemLayout} name="receiptName" rules={[{ required: true }]} disabled label="收件人">
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item {...formItemLayout} name="receiptPhone" rules={[{ required: true }]} label="联系电话">
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item {...formItemLayout} name="receiptAddress" rules={[{ required: true }]} label="收件地址">
            <Input placeholder="请输入" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )

}

export default CompanyInfoPage