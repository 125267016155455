import React,{useImperativeHandle,forwardRef} from 'react'
import { 
  Modal,
  Upload,
  Form,
  Button,
  message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons'
import axios from 'axios'
const FormItem = Form.Item;
const MyModel = (props,ref) => {
  const [form] = Form.useForm()
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fileList, setFileList] = React.useState([]) //文件列表
  const fileProps = {
    //表格上传配置和方法
    name: 'files',
    fileList: fileList,
    multiple: false,
    accept: '.xls, .xlsx',
    beforeUpload: () => {
      return false
    },
    onChange(info) {
      let fileCon = [...info.fileList]
      //限制文件数量，只会显示最近的一个文件，其他将被最新的文件覆盖
      fileCon = fileCon.slice(-1)
      setFileList(fileCon)
    }
}
   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (val) => {
      setVisible(true);
      form.resetFields()
    },
  }));
  
  const methodsFn =  {
     //关闭新增弹窗
     onImportCancel() {
      setVisible(false)
      form.resetFields()
      setFileList([])
  },
    // 上传文件
    async onHandleUp() {
      let values = await form.validateFields()
      let data = new FormData() //通过FormData将文件转成二进制数据
      let file = values.ffile.file
      data.append('token', localStorage.getItem('token'))
      data.append('mobile', localStorage.getItem('mobile'))
      data.append('file', file)
      setLoading(true)
      axios({
        method: 'post',
        url: `/hire/company/cityStore/importCityStore`,
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(({ data: { retMsg, success } }) => {
        setLoading(false)
        if (success) {
          setVisible(false);
          setLoading(false);
          message.success(retMsg)
          props.refresh()
        } else {
          message.error(retMsg)
        }
      }).catch(err => {
        setLoading(false)
        console.log(err)
      })
    },
  }

  return (
    <>
      <Modal maskClosable={false} title='导入' confirmLoading={loading} visible={visible} onOk={form.submit} onCancel={methodsFn.onImportCancel}>
        <Form form={form} onFinish={methodsFn.onHandleUp} >
            <FormItem label='文件上传' name='ffile' rules={[{ required: true, message: '请上传文件' }]}>
                <Upload {...fileProps}>
                  <Button icon={<UploadOutlined />}>点击上传</Button>
                  <a onClick={() => window.location.href = `/hire/common/getTemplateDownloadUrl?token=${localStorage.getItem("token")}&mobile=${localStorage.getItem("mobile")}&type=QY_CSMD`}>下载模板</a>
                </Upload>
            </FormItem>
        </Form>
      </Modal>
    </>
  );
};
export default forwardRef(MyModel)