import React,{useRef,useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Radio,
  Button,
  message,
  DatePicker,
  Modal,
  Space
 } from 'antd';
 import qs from 'qs'
import {request} from '../../../../utils/fetch'
import moment from 'moment';
import InvoiceApplyDetail from './InvoiceApplyDetail'
import store from '../../../../store/index' 

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
};
function InvoiceApply(props) {
    let path = 'company:CompanyTicket'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const initSearch = {
        date:[],
    }
    const [dayValue, setDayValue] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [tableData,setTableData] = useState([])
    const [visible, setVisible] = React.useState(false);
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [isDetail,setIsDetail] = useState(false)
    const [parmObj,setParmObj] = useState({})       //传给明细的参数
    const [rows,setRows] = useState([])
    const [totalMoney,setTotalMoney] = useState(0)       //开票金额

    const columns = [
        {
            title: '支付完成时间',
            dataIndex: 'payTime',
            key: 'payTime',
            width: 180,
            align:'center',
        }, {
            title: '任务名称',
            dataIndex: 'taskName',
            key: 'taskName',
            width: 160,
            align:'center',
        },{
            title: '任务单号',
            dataIndex: 'billNo',
            key: 'billNo',
            width: 180,
            align:'center',
        }, 
        {
            title: '支付金额',
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
            width: 150,
            align:'center',
        },  {
            title: '可申请开票金额',
            dataIndex: 'ticketAmount',
            key: 'ticketAmount',
            width: 150,
            align:'center',
        }, 
        {
        title: '操作',
        key: 'action',
        width: 100,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            <Space size="middle">
                {store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a onClick={() => methodsFn.goDetail(record) }>明细</a>}
            </Space>
        ),
        },
    ];
   
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="支付完成时间" name="date" style={{marginLeft:'15px'}}>
                        <RangePicker 
                            onChange={methodsFn.timeChange}
                        />
                    </FormItem>
                    <FormItem style={{with:'600px',marginLeft:'26px'}} label="">
                        <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                            <Radio value={1}>今天</Radio>
                            <Radio value={7}>近7天</Radio>
                            <Radio value={30}>近30天</Radio>
                        </Radio.Group>
                    </FormItem>
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap', marginBottom: '0px' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                        {store.getState().userReducer.permissions.indexOf(`${path}Apply:apply`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleApply}>申请</Button>}
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1,'d').format('YYYY-MM-DD'),
                endDate = moment().format('YYYY-MM-DD');
            
            setDayValue(e.target.value)
            form.setFieldsValue({
                date:[moment(startDate),moment(endDate)]
            })
        },
        timeChange(dates,dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate),moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date:dateArr
            })
         
        },
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let beginTime = '',
                endTime = '';
            if(formObj.date.length > 1){
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            }else{
                beginTime = ''
                endTime = ''
            }
            let obj = {
                payBeginDate:beginTime,
                payEndDate:endTime,
                taxEnterpriseCode:formObj.taxEnterpriseCode,
            }
            return obj
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setDayValue('')
            form.resetFields();
        },
        async getTableList(searchObj){
           
            let result = await request.get('/company/companyTicket/getList',{
               ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        tableChange(key,row){
            setRows(row)
        },
        handleApply(){   
            
            if(rows.length < 1){
                message.error('请先勾选列表')
                return
            }
            let index = rows.findIndex(e=>e.taxEnterpriseCode!=rows[0].taxEnterpriseCode)
            if(index>-1){
                message.error('不能选择不同结算通道的待开票记录')
                return
            }
            let result =  rows.reduce((pre,cur) => {
                return pre + cur.ticketAmount
            },0)
            setTotalMoney(result.toFixed(2))
            setVisible(true)
        },
        handleInvoice(){
            setLoading(true);
            let obj = form2.getFieldsValue()
            let ticketNoList = rows.map(item => item.ticketNo)
            request.post('/company/companyTicket/apply',{
                ticketAmount:totalMoney,
                ticketNoList:ticketNoList.join(','),
            }).then(res => {
                setLoading(false);
                setVisible(false)
                methodsFn.refresh()
            }).catch(err => setLoading(false))
        },
    
        refresh(){
            methodsFn.handleSearch()
        },
        goDetail(record){
            setIsDetail(true)
            props.history.push('/admin/invoiceApply?settlementNo='+record.settlementNo+'&ticketNo='+record.ticketNo+'&ticketApplyNo='+record.ticketApplyNo)
            setParmObj({
                settlementNo:record.settlementNo,
                ticketNo:record.ticketNo,
                ticketApplyNo:record.ticketApplyNo,
                
            })
        },
        detailFn(){
            setIsDetail(false)
            props.history.push('/admin/invoiceApply')
        },
        handleExport(){
            request.downExport('/company/companyTicket/export',methodsFn.getsearchObj())
        },
    }   
    useEffect(() => {
        if(props.location.search){       //解决明细页面刷新问题
            methodsFn.goDetail({
                settlementNo:searchParam.settlementNo,
                ticketNo:searchParam.ticketNo,
                ticketApplyNo:searchParam.ticketApplyNo,
            })
        }
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    
    },[pageNum])

    return (
        <div>
                {
                    !isDetail?
                    <div>
                    {myComponent.renderSearchForm()}
                    <Table 
                        rowSelection={{
                            type: "checkbox",
                            onChange:methodsFn.tableChange,
                        }}
                        scroll={{ x: 'max-content' }}
                        columns={columns} 
                        rowKey={(record) => record.ticketNo}
                        dataSource={tableData} 
                        pagination={{
                            position: ['bottomCenter'],
                            total: total,
                            current: pageNum,
                            pageSize: pageSize,
                            showSizeChanger:false,
                            showTotal:(total) =>  `共 ${total} 条数据` ,
                            onChange: (pageNum, pageSize) => setPageNum(pageNum)
                        }}
                    />
                 </div>
                 : <InvoiceApplyDetail isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn}/>
                 }

            <Modal
                title='申请开票'
                visible={visible}
                confirmLoading={loading}
                onOk={methodsFn.handleInvoice}
                onCancel={() => setVisible(false) }
            >
                <Form
                    form={form2}
                    {...formItemLayout}
                    initialValues={{
                        planType:''
                    }}
                    >
                    <FormItem
                      label="开票金额"
                      name="ticketAmount">
                        <Input disabled={true} placeholder={totalMoney} />
                    </FormItem>
                </Form>
            </Modal>
        </div>
    )
}

export default InvoiceApply
