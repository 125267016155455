import React,{useState,useEffect} from 'react'
import {
  Space,
  Table,
  Button,
  Modal,
  Input,
  Form,
  message,
  Typography,
  Statistic
 } from 'antd';
 import { AccountBookOutlined } from '@ant-design/icons'
import { request } from '../../../../utils/fetch'
import store from '../../../../store/index' 
import { decimalAdd } from '../../../../utils/auth'
const { Paragraph } = Typography;
const itemStyle = {
    marginBottom: '15px'
}
function CapitalAccount(props) {
    let path = 'company:CompanyBase'
    // company:CompanyBase:exportAccountsCapitalDetail
    const [tableData,setTableData] = useState([])
    const [visible, setVisible] = React.useState(false);
    const [visible1, setVisible1] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [totalMoney,setTotalMoney] = useState()       //开票金额
    const [recordData,setRecordData] = useState({})     
    const [rechargeNo,setRechargeNo] = useState()     
    const [accountName,setAccountName] = useState()     
    const [accountNo,setAccountNo] = useState()     
    const [bankName,setBankName] = useState()     
    const [form] = Form.useForm()
    const columns = [
        {
            title: '结算通道',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            align:'center',
        },
         {
            title: '账户余额(元)',
            dataIndex: 'availableBalance',
            key: 'availableBalance',
            align:'center',
        },
        {
            title: '操作',
            key: 'action',
            align:'center',
            width: 120,
            render: (text, record) => (
                <Space size="middle">
                   <Button type="link" onClick={()=>{methodsFn.getCompanyAccounts(record)}} >充值</Button>
                   <Button type="link" onClick={()=>{methodsFn.goDetail(record)}} >交易明细</Button>
                   <Button type="link" onClick={()=>{methodsFn.goDetailPay(record)}} >充值记录</Button>
                </Space>
            ),
        },
    ];
    //方法函数
    const methodsFn =  {
        handleOk(){
            setVisible1(true)
            form.setFieldsValue({ 
                bankAccountNo: recordData.companyBase.bankNumber,
                bankAccountName: recordData.companyBase.bankAccountName,
                bankName: recordData.companyBase.bankName,
            })
        },
        async handleOk1(){
            setLoading(true)
            try {
                let values = await form.validateFields()
                let data = {
                    rechargeNo: rechargeNo,
                    taxEnterpriseCode: recordData.taxEnterpriseCode,
                    companyCode: recordData.companyCode,
                    companyName: recordData.companyName,
                    bankAccountNo: values.bankAccountNo,
                    bankAccountName: values.bankAccountName,
                    bankName: values.bankName,
                    amount: values.amount,
                }
                request.post('/company/companyAccount/rechargeApply',{...data}).then(res => {
                    message.success(res.msg)
                    setVisible1(false) 
                    setVisible(false)
                    methodsFn.getTableList()
                    setLoading(false)
                })
            } catch (error) {
                setLoading(false)
            }
        },
        onCancel1(){
            form.resetFields()
            setVisible1(false)
        },
        async getTableList(){
            let result = await request.get('/company/companyAccount/list')
            if(result){
                // 计算 result 中  availableBalance 的总和
                let totals = result.map((item=>{
                    return item.availableBalance
                }))
                setTotalMoney(decimalAdd(totals))
            }
            setTableData(result)
        },
        goDetail(record){
            props.history.push('/admin/capitalAccount/detail?taxEnterpriseCode='+record.taxEnterpriseCode)
        },
        goDetailPay(record){
            props.history.push('/admin/capitalAccount/detailPay?taxEnterpriseCode='+record.taxEnterpriseCode)
        },
        async getCompanyAccounts(record){
            setAccountName(record.enterpriseAccount.accountName)
            setAccountNo(record.enterpriseAccount.accountNo)
            setBankName(record.enterpriseAccount.bankName)
            setRecordData(record)
            setVisible(true)
            let result = await request.get('/company/companyAccount/getRemitCode')
            console.log(result)
            setRechargeNo(result)
        },
    }   
    useEffect(() => {
        methodsFn.getTableList()
    },[])

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', 'background': '#fff', 'marginBottom': '10px', 'padding': '20px', }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '80px',height: '80px',borderRadius: '50%', backgroundColor: '#62d1aa', margin: '0 20px'}} >
                    <AccountBookOutlined style={{fontSize: '26px', color: '#fff'}} />
                </div>
                <div>
                    <div style={{fontSize: '16px', color: '#666'}}>账户总余额</div>
                    <div><Statistic valueStyle={{ 'fontSize': '28px', color: '#333', 'fontWeight': '700' }} value={totalMoney} />{}</div>
                </div>
            </div>
            <Table 
                scroll={{ x: 'max-content' }}
                columns={columns} 
                rowKey={(record) => record.id}
                dataSource={tableData} 
                pagination={false}
            />
            <Modal
                title='账户充值' visible={visible} onCancel={() => setVisible(false) }
                footer={[
                    <div key='play' style={{textAlign: 'center'}} >
                        <Button type="primary" onClick={()=>{methodsFn.handleOk()}} >我已支付</Button>
                    </div>
                  ]} 
                >
                <div style={{fontSize: '18px',color: '#333',marginBottom: '10px',fontWeight: '700'}} >
                    <span>结算通道:</span>
                    <span>{recordData.taxEnterpriseName}</span>
                </div>
                <div style={{backgroundColor: '#fff2cb', borderRadius: '10px',padding: '15px' }} >
                    <div style={{ fontSize: '18px', color: '#333', marginBottom: '10px' }} >转账说明</div>
                    <div style={{color: 'red', fontSize: '14px'}} >1、线下转款请将识别码填写到转账汇款“备注中”</div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        您的汇款识别码是：
                        <Paragraph style={{color: '#FF0000', marginBottom: '0px'}}
                            copyable={{
                                tooltips: ['点击复制', '复制成功'],
                            }}
                        >{rechargeNo}</Paragraph>
                    </div>
                    <div>
                        2、收款账号：
                    </div>
                    <div>开户行：{bankName}</div>
                    <div>开户名：{accountName}</div>
                    <div>收款账号：{accountNo}</div>
                    <div>注意事项：</div>
                    <div>
                        1、线下汇款时间较长，汇款成功后请及时确认汇款信息是否正确
                    </div>
                    <div>
                        2、请在工作日内汇款。
                    </div>
                    <div>
                        3、请按照应付金额进行汇款，不要多汇或者少汇
                    </div>
                </div>
            </Modal>
            <Modal
                title='支付确认' visible={visible1} onCancel={() => setVisible1(false) }
                footer={[
                    <Button key='b1' loading={loading} onClick={()=>{methodsFn.onCancel1()}} >取消</Button>,
                    <Button key='b2' loading={loading} type="primary" onClick={()=>{methodsFn.handleOk1()}} >确认</Button>
                  ]} 
                >
                <Form form={form} labelCol={{ flex: '120px' }} wrapperCol={{ flex: '1' }} >
                    <div style={{color: '#000000d9',fontWeight: '600', fontSize: '16px', lineHeight: '1.5' }} >汇款信息</div>
                    <Form.Item label='汇款识别码:' style={itemStyle} >
                        {rechargeNo}
                    </Form.Item>
                    <Form.Item name='bankAccountName' label='开户名:' style={itemStyle} rules={[{ required: true, message: '请输入开户名' }]} >
                        <Input />
                    </Form.Item>
                    <Form.Item name='bankAccountNo' label='汇款账号:' style={itemStyle} rules={[{ required: true, message: '请输入汇款账号' }]} >
                        <Input />
                    </Form.Item>
                    <Form.Item name='bankName' label='汇款银行:' style={itemStyle} rules={[{ required: true, message: '请输入汇款银行' }]} >
                        <Input />
                    </Form.Item>
                    <Form.Item name='amount' label='汇款金额:' style={itemStyle} rules={[{ required: true, message: '请输入汇款金额' }]} >
                        <Input />
                    </Form.Item>
                    <div className='acctitle' >收款信息</div>
                    <Form.Item label='开户行:' style={{marginBottom: '0px'}} >
                        {bankName}
                    </Form.Item>
                    <Form.Item label='开户名:' style={{marginBottom: '0px'}} >
                        {accountName}
                    </Form.Item>
                    <Form.Item label='收款账号:' style={{marginBottom: '0px'}} >
                        {accountNo}
                    </Form.Item>
            </Form>
            </Modal>
        </div>
    )
}

export default CapitalAccount
