import React,{useRef,useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Radio,
  Button,
  message,
  DatePicker,
  Popconfirm,
  Space
 } from 'antd';
import qs from 'qs'
import {request} from '../../../../utils/fetch'
import moment from 'moment';
import InvoiceRecordDetail from './InvoiceRecordDetail'
import MyModel from './MyModel';
import store from '../../../../store/index'

const FormItem = Form.Item;
const { Option } = Select;
const { RangePicker } = DatePicker;
const itemStyle = {
     width:'380px',
     marginRight:'15px'
}
const labelStyleObj = {
    labelCol:{ span: 6},
    wrapperCol:{ span: 18}
 }

function InvoiceRecord(props) {
    let path = 'company:CompanyTicketApply'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
    const [form] = Form.useForm();
    const myModel = useRef();
    
    const initSearch = {
        status: null,
        date: []
    }
    const [dayValue, setDayValue] = React.useState('');
    const [tableData,setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [isDetail,setIsDetail] = useState(false)
    const [detailObj,setDetailObj] = useState({})       //查看详情
    const [parmObj,setParmObj] = useState({})       //传给明细的参数

    const columns = [
        {
            title: '申请时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 190,
            align:'center',
        }, 
        {
            title: '申请开票金额',
            dataIndex: 'ticketAmount',
            key: 'ticketAmount',
            width: 150,
            align:'center',
        },{
            title: '开票状态',
            dataIndex: 'statusName',
            key: 'statusName',
            width: 150,
            align:'center',
        },
        {
        title: '操作',
        key: 'action',
        width: 100,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            <Space size="middle">
                {store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a onClick={() => methodsFn.handleLook(record) }>查看</a>}
                {
                   store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&(record.ticketTypeName != '阶梯退费')
                    ?<a onClick={() => methodsFn.goDetail(record) }>明细</a>
                    :null
                }
            </Space>
        ),
        },
    ];
   
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="申请时间" name="date" style={{marginLeft:'25px'}}>
                        <RangePicker 
                            onChange={methodsFn.timeChange}
                        />
                    </FormItem>
                    <FormItem style={{with:'600px',marginLeft:'26px'}} label="">
                        <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                            <Radio value={1}>今天</Radio>
                            <Radio value={7}>近7天</Radio>
                            <Radio value={30}>近30天</Radio>
                        </Radio.Group>
                    </FormItem>
                </Row>
                <Row>
                    <FormItem label="开票状态" name="status" style={itemStyle} {...labelStyleObj}>
                        <Select>
                            <Option value={'YSQ'}>待处理</Option>
                            <Option value={'KPCG'}>开票成功</Option>
                        </Select>
                    </FormItem>
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap', marginBottom: '0px' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1,'d').format('YYYY-MM-DD'),
                endDate = moment().format('YYYY-MM-DD');
            
            setDayValue(e.target.value)
            form.setFieldsValue({
                date:[moment(startDate),moment(endDate)]
            })
        },
        timeChange(dates,dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate),moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date:dateArr
            })
         
        },
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let beginTime = '',
                endTime = '';
            if(formObj.date.length > 1){
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            }else{
                beginTime = ''
                endTime = ''
            }
            let obj = {
                applyBeginDate:beginTime,
                applyEndDate:endTime,
                status:formObj.status,
                taxEnterpriseCode:formObj.taxEnterpriseCode,
            }
            return obj
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setDayValue('')
            form.resetFields();
        },
        async getTableList(searchObj){
          
            let result = await request.get('/company/companyTicketRecord/getList',{
               ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        async getDetail(obj){
            const result = await request.get('/company/companyTicketRecord/getDetail',{
                ticketApplyNo:obj.ticketApplyNo,
                companyCode:obj.companyCode,
            })
            setDetailObj(result)
       },
        handleLook(record){
            methodsFn.getDetail(record)
            myModel.current.showModal(true)
       },
        refresh(){
            methodsFn.handleSearch()
        },
        goDetail(record){
            setIsDetail(true)
            props.history.push('/admin/invoiceRecord?companyCode='+record.companyCode+'&ticketApplyNo='+record.ticketApplyNo)
            setParmObj({
                companyCode:record.companyCode,
                ticketApplyNo:record.ticketApplyNo,
            })
        },
        detailFn(){
            setIsDetail(false)
            props.history.push('/admin/invoiceRecord')
        },
        handleExport(){
            request.downExport('/company/companyTicketRecord/export',methodsFn.getsearchObj())
        },
    }   
    useEffect(() => {
        if(props.location.search){       //解决明细页面刷新问题
            methodsFn.goDetail({
                ticketApplyNo:searchParam.ticketApplyNo,
                companyCode:searchParam.companyCode,
            })
        }
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    
    },[pageNum])

    return (
        <div>
                {
                    !isDetail?
                    <div>
                    {myComponent.renderSearchForm()}
                    <Table 
                        scroll={{ x: 'max-content' }}
                        columns={columns} 
                        rowKey={(record) => record.ticketApplyNo}
                        dataSource={tableData} 
                        pagination={{
                            position: ['bottomCenter'],
                            total: total,
                            current: pageNum,
                            pageSize: pageSize,
                            showSizeChanger:false,
                            showTotal:(total) =>  `共 ${total} 条数据` ,
                            onChange: (pageNum, pageSize) => setPageNum(pageNum)
                        }}
                    />
                 </div>
                 : <InvoiceRecordDetail isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn}/>
                 }
           <MyModel cRef={myModel} detailObj={detailObj} refresh={methodsFn.refresh}/>

     
        </div>
    )
}

export default InvoiceRecord
