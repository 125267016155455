import React from 'react'
import { setToken, setMobile, setUsername, setMenu, setPermissions } from '../utils/auth'
import { Form, Input, Button, message, Tabs } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import store from '../store/index'
import {setUser, setPower} from '../store/Action'
import { withRouter } from "react-router-dom";
import { request } from '../utils/fetch'
import CryptoJS from 'crypto-js';
import logo from '../images/logo1.png'
const { TabPane } = Tabs;
let second = 60
function Login(props) {
    const [form2] = Form.useForm();
    const [disabled, setDisabled] = React.useState(false);
    const [btnTxt, setBtnTxt] = React.useState('获取验证码');


    const encodeAesString = (data, key, iv) => {
        var key = CryptoJS.enc.Utf8.parse(key);
        var iv = CryptoJS.enc.Utf8.parse(iv);
        var encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        //返回的是base64格式的密文	
        return encrypted.toString();
    }
    const onFinish = async (values) => {
        var key = 'fdf653gsdg34ferg'; // 密钥 长度16  前后端定义好，加盐
        var iv = 'fdf653gsdg34ferg'; // 密钥 长度16
        var encrypted = encodeAesString(values.password, key, iv); // 密文

        var result = await request.post('/company/login', {
            phone: values.phone,
            password: encrypted
        })
        message.success('登录成功')
        localStorage.setItem('companyName', result.companyName)
        localStorage.setItem('company', result.companyCode)
        localStorage.setItem('directFlag', result.directFlag)
        store.dispatch(setUser(result.username))
        setToken(result.token)
        setMobile(result.mobile)
        setUsername(result.username)
        setMenu(JSON.stringify(result.firstData))
        if(result.permissionsStr){
            setPermissions(JSON.stringify(result.permissionsStr))
            store.dispatch(setPower(result.permissionsStr))// 菜单权限
        }else{
            setPermissions([])
            store.dispatch(setPower([]))// 菜单权限
        }
        // 路由跳转
        props.history.push("/admin/home");
    }
    const onFinish2 = async (values) => {
        var result = await request.post('/company/loginByCode', {
            phone: values.phone,
            code: values.code
        })
        message.success('登录成功')
        localStorage.setItem('companyName', result.companyName)
        localStorage.setItem('company', result.companyCode)
        localStorage.setItem('directFlag', result.directFlag)
        store.dispatch(setUser(result.username))
        setToken(result.token)
        setMobile(result.mobile)
        setUsername(result.username)
        setMenu(JSON.stringify(result.firstData))
        // 路由跳转
        props.history.push("/admin/home");
    }
    const timerFn = () => {
        setTimeout(() => {
            if (second > 0) {
                setDisabled(true)
                setBtnTxt(`${second}s`)
                second--
                timerFn()
            } else {
                second = 60
                setDisabled(false)
                setBtnTxt('获取验证码')
            }

        }, 1000);

    }
    const getCode = () => {
        let obj = form2.getFieldsValue()
        if (!obj.phone) {
            message.error('请先输入手机号！')
            return
        }
        request.get('/common/getCode', {
            systemType: 2,
            phone: obj.phone,
            type: 1      //1登录 2注册 3修改密码
        }).then(res => {
            timerFn()
        }).catch(err => { })

    }
    const callback = (key) => {
        console.log(key);
    }

    return (
        <div className="login-wrap">
            <div className="logo-txt">
                <img className="logo-t1" src={logo} alt="" />
                <div className='sysname' >欢迎加入快活用工灵活用工平台</div>
                <div className='sysline' ></div>
            </div>
            <div className="login-tab">
                <Tabs defaultActiveKey="1" onChange={callback} >
                    <TabPane tab="密码登录" key="1">
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{
                                phone: '',
                                password: ''
                            }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入手机号',
                                    },
                                ]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入手机号" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入密码',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="请输入密码"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: "100%" }}>
                                    登录
                                    </Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                    <TabPane tab="验证码登录" key="2">
                        <Form
                            name="normal_login2"
                            className="login-form"
                            onFinish={onFinish2}
                            form={form2}
                            initialValues={{
                                phone: '',
                            }}
                        >
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入手机号',
                                    },
                                ]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入手机号" />
                            </Form.Item>
                            <Form.Item
                                name="code"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入验证码',
                                    },
                                ]}
                            >
                                <div className='lgcode-box'>
                                    <Input
                                        className='lgcode-input'
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        placeholder="请输入验证码"
                                    />
                                    <Button
                                        type="primary"
                                        disabled={disabled}
                                        onClick={getCode}
                                        className='lgcode-btn'
                                    >
                                        {btnTxt}
                                    </Button>
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button">
                                    登录
                                    </Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                </Tabs>
            </div>
            <div className='copyright'>
                {/* <div>地址：江西省南昌市南昌县汇仁大道255号凌克大厦1楼</div> */}
                <div>
                Copyright © 2024 版权所有<a style={{ marginLeft:'5px', color: '#fff' }} target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">赣ICP备19013934号-5</a>
                </div>
            </div>
        </div>
    )
}


export default withRouter(Login)
