import React, { useState, useEffect } from 'react'
import {
  Input,
  Card,
  PageHeader,
  Row,
  Col,
  Empty
} from 'antd'
import { getHistoryConfig } from './api'

function CompanyHistoryConfig(props) {
  const [loading, setLoading] = useState(false)
  const state = props.location.state
  const [configData, setConfigData] = useState([])
  useEffect(() => {
    getConfig()
  }, [])
  const getConfig = async() => {
    try {
      setLoading(true)
      const res = await getHistoryConfig()
      console.log(res)
      setLoading(false)
      if (res) {
        setConfigData(res)
      } else {
      }
    } catch(err) {
      setLoading(false)
      console.log('__获取历史配置', err)
    }
  }
  return (
    <PageHeader title="历史配置" onBack={() => props.history.goBack()}>
      <div className="history-config">
        <div className="top">企业名称：<Input value={state.companyName} style={{width: '320px'}}></Input></div>
        {
          configData.length ? configData.map((item, index) => (
            <Card title={item.effectTime} loading={loading} key={'config' + index} style={{margin: '10px 0'}}>
            {
              item.list.length > 0 && item.list.map((node, index) => (
                <div key={index}>
                  {node.planType === 1 && <div className="card-title" style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>临时税务登记</div>}
                  {node.planType === 2 && <div className="card-title" style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>个体工商户</div>}
                  {node.planType === 3 && <div className="card-title" style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>委托代征</div>}
                  <Row gutter={[4, 10]}>
                    <Col span="24" style={{display: 'flex', justifyContent: 'flex-start'}}>
                      <span>常规服务费：</span>
                      {
                        <div>
                          <p>每笔{node.serviceValue}%</p>
                        </div>
                      }
                    </Col>
                    <Col span="24" style={{display: 'flex', justifyContent: 'flex-start'}}>
                      <span>是否发送充值确认结果短信：</span>
                      {
                        <div>
                          <p>{node.sendConfirmFlag?'是':'否'}</p>
                        </div>
                      }
                    </Col>
                    <Col span="24" style={{display: 'flex', justifyContent: 'flex-start'}}>
                      <span>是否直发：</span>
                      {
                        <div>
                          <p>{node.directFlag?'是':'否'}</p>
                        </div>
                      }
                    </Col>
                  </Row>
                </div>
              ))
            }
            </Card>
          )) : <Empty />
        }
      </div>
    </PageHeader>
    
  )
}

export default CompanyHistoryConfig