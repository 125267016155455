import React, { useRef, useState, useEffect } from 'react'
import {
    Row,
    Col,
    Table,
    Select,
    Form,
    Input,
    Button,
    message,
    Upload,
    Popconfirm,
    Modal,
    Space
} from 'antd';
import qs from 'qs'
import axios from 'axios'
import { UploadOutlined, } from '@ant-design/icons';
import { request } from '../../../../utils/fetch'
import store from '../../../../store/index'
import { useThrottle } from '../../../../utils/auth'
import { decimalAdd } from '../../../../utils/auth'
import NextDetail from './NextDetail'
import CommissionDetail2 from './CommissionDetail2'

const FormItem = Form.Item;
const { Option } = Select;
const itemStyle = {
    width: '310px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
}
const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};
function CommissionUpload(props) {
    let path = 'company:BillUpload'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g, ''));
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const initSearch = {
        // stepStatus: '',
    }
    const [visible1, setVisible1] = React.useState(false);
    const [errList, setErrList] = useState([])
    const [errData, setErrData] = useState({ list: [], totalCount: 0 })
    const [tableData, setTableData] = useState([])
    const [recordData, setRecordData] = useState([])
    const [visible2, setVisible2] = React.useState(false);
    const pageSize = 20     //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [isDetail, setIsDetail] = useState(false)
    const [parmObj, setParmObj] = useState({})       //传给明细的参数    
    const [visible, setVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loading1, setLoading1] = React.useState(false);

    const fileProps = {
        name: 'files',
        fileList: fileList,
        multiple: false,
        accept: '.xls, .xlsx',
        beforeUpload: file => {
            return false;
        },

        onChange(info) {
            let fileCon = [...info.fileList];
            //限制文件数量，只会显示最近的一个文件，其他将被最新的文件覆盖
            fileCon = fileCon.slice(-1);
            setFileList(fileCon)
        },
    };
    const columns = [
        {
            title: '上传时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 190,
            align: 'center',
        },
        {
            title: '任务单号',
            dataIndex: 'billNo',
            key: 'billNo',
            width: 170,
            align: 'center',
        },
        {
            title: '任务名称',
            dataIndex: 'taskName',
            key: 'taskName',
            width: 200,
            align: 'center',
        },
         {
            title: '结算通道',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            width: 220,
            align: 'center'
        }, {
            title: '参与人数',
            dataIndex: 'staffCount',
            key: 'staffCount',
            width: 110,
            align: 'center',
        }, {
            title: '任务总额(元)',
            dataIndex: 'totalCommissionAmount',
            key: 'totalCommissionAmount',
            width: 120,
            align: 'center',
            render: (totalCommissionAmount,record)=>{
                return decimalAdd([totalCommissionAmount,record.totalServiceAmount])
            }
        }, {
            title: '状态',
            dataIndex: 'stepStatusName',
            key: 'stepStatusName',
            width: 130,
            align: 'center',
        },{
            title: '操作',
            key: 'action',
            width: 240,
            align: 'center',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">
                    {
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        record.stepStatus == 2&&<a type="link" onClick={() => {
                            var downloadUrl = window.location.origin + record.fileUrl,
                                fileName = record.fileName
                            var loadUrl =
                                `/hire/common/pageUrlDownload?token=${localStorage.getItem("token")}&mobile=${localStorage.getItem("mobile")}&fileName=${fileName}&urlStr=${downloadUrl}`
                            window.location.href = loadUrl
                        }}>下载</a >
                    }
                    {
                        record.stepStatus == 2 && store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1 &&
                        <a onClick={() => methodsFn.handleErrInfor(record)}>查看错误信息</a>
                    }
                    {
                        store.getState().userReducer.permissions.indexOf(`${path}:remove`) > -1 && record.stepStatus == 2
                            ? <Space size="middle">
                                {store.getState().userReducer.permissions.indexOf(`${path}:remove`) > -1 && <Popconfirm
                                    title="确定删除吗？"
                                    onConfirm={() => {
                                        methodsFn.handleDel(record)
                                    }}
                                    okText="确定"
                                    cancelText="取消"
                                    className="marginLeft"
                                >
                                    <a>删除</a>
                                </Popconfirm>}
                            </Space>
                            : null
                    }
                    {store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1 && (record.stepStatus == 4||record.stepStatus == 1) ? <Button type="link" onClick={() => methodsFn.goDetail(record)}>明细</Button> : null}
                    {
                        record.stepStatus == 1&&store.getState().userReducer.permissions.indexOf(`${path}:removeSettlement`) > -1 && 
                        <Popconfirm
                            title={`确定放弃生成结算单么？`}
                            onConfirm={() => methodsFn.handleCancel(record)}
                            okText="确定"
                            cancelText="取消"
                            className="marginLeft"
                        >
                            <a>放弃</a>
                        </Popconfirm>}
                    {
                        record.stepStatus == 1&&store.getState().userReducer.permissions.indexOf(`${path}:createSettlement`) > -1 && 
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a onClick={() => {methodsFn.onGenerate(record)} } >生成结算单</a>
                    }
                </Space>
            ),
        },
    ];

    //组件渲染函数
    const myComponent = {
        renderSearchForm() {
            return (
                <Form form={form} initialValues={initSearch}>
                    <Row>
                        <FormItem label="任务名称" name="taskName" style={itemStyle} {...labelStyleObj} >
                            <Input placeholder="请输入" />
                        </FormItem>
                        <FormItem label="数据处理状态" name="stepStatus" style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder="全部">
                                <Option value={'0'}>上传处理中</Option>
                                <Option value={'1'}>上传完成</Option>
                                <Option value={'2'}>上传失败，有错误数据</Option>
                                <Option value={'3'}>结算单生成中</Option>
                                <Option value={'4'}>已生成结算单</Option>
                                <Option value={'5'}>放弃生成结算单</Option>
                            </Select>
                        </FormItem>
                    </Row>
                    <div style={{display: 'flex',justifyContent: 'end',alignItems: 'center'}} >
                        <div>
                            <FormItem style={{ marginBottom: '0px' }} >
                                <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                                    {store.getState().userReducer.permissions.indexOf(`${path}:add`) > -1 && <Button
                                    type="primary"
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => {
                                        setFileList([])
                                        form2.resetFields()
                                        setVisible2(true)
                                    }}>
                                    上传文件
                                </Button>}
                            </FormItem>
                        </div>
                    </div>
                </Form>
            );
        },
    }
    //方法函数
    const methodsFn = {
        async handleErrInfor(record) {
            setVisible1(true)
            let result = await request.get('/company/billUpload/errorMessage', {
                importCode: record.importCode,
            })
            setErrList(result.list)
            setErrData(result)
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(form.getFieldsValue())
        },
        handleFormReset() {
            form.resetFields();
        },
        handleCancel(record) {
            request.post('/company/billUpload/removeSettlement', {
                billNo: record.billNo,
            }).then(res => {
                message.success(res.msg)
                methodsFn.handleSearch()
            }).catch(() => setLoading(false))
        },
        async getTableList(searchObj) {
            let result = await request.get('/company/billUpload/getRecord', {
                pageSize: pageSize,
                pageNum: pageNum,
                taskName: searchObj.taskName,
                stepStatus: searchObj.stepStatus,
                taxEnterpriseCode: searchObj.taxEnterpriseCode,
            })
            setTotal(result.totalCount)
            setTableData(result.list)
        },
        handleDel(record) {
            request.get('/company/billUpload/deleteRecord', {
                importCode: record.importCode,
            }).then(res => {
                message.success(res.msg)
                methodsFn.handleSearch()
            })
        },
        onGenerate(record) {
            setVisible(true)
            setRecordData(record)
        },
        handleOk: useThrottle(()=> {
            setLoading(true);
            request.post('/company/billUpload/createSettlement', {
                billNo: recordData.billNo,
            }).then(res => {
                setVisible(false);
                setLoading(false);
                message.success(res.msg)
                methodsFn.handleSearch()
            }).catch(() => setLoading(false))
        }, 1000),
        async handleUp() {
            setLoading1(true)
            try {
                let values = await form2.validateFields()
                let data = new FormData(); //通过FormData将文件转成二进制数据
                let file = values.ffile.file
                data.append('token', localStorage.getItem('token'))
                data.append('mobile', localStorage.getItem('mobile'))
                data.append('taskName', values.taskName)
                data.append('file', file)
                axios({
                    method: 'post',
                    url: `/hire/company/billUpload/uploadBillFile`,
                    data: data,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    if (res.data.success) {
                        setVisible2(false)
                        message.success(res.data.retMsg)
                        methodsFn.handleSearch() 
                    } else {
                        message.error(res.data.retMsg)
                    }
                    setLoading1(false)
                }).catch(err => {
                    setLoading1(false)
                    console.log(err)
                })
            } catch (error) {
                setLoading1(false)
            }
        },
        goDetail(record) {
            setIsDetail(true)
            props.history.push('/admin/commissionUpload?importCode=' + record.importCode + '&taskStatusName=' + record.taskStatusName)
            setParmObj({
                billNo: record.billNo,
                taskStatusName: record.taskStatusName,
                stepStatus:record.stepStatus
            })
        },
        detailFn() {
            setIsDetail(false)
            props.history.push('/admin/commissionUpload')
            methodsFn.handleSearch()
        },


    }
    useEffect(() => {
        if (props.location.search) {       //解决明细页面刷新问题
            methodsFn.goDetail({
                importCode: searchParam.importCode,
                taskStatusName: searchParam.taskStatusName,

            })
        }
    }, [])
    useEffect(() => {
        methodsFn.getTableList(form.getFieldsValue())

    }, [pageNum])
    return (
        <div>
            {
                !isDetail
                    ? <div>
                        {myComponent.renderSearchForm()}
                        <Table
                            scroll={{ x: 'max-content' }}
                            columns={columns}
                            rowKey={(record) => record.id}
                            dataSource={tableData}
                            pagination={{
                                position: ['bottomCenter'],
                                total: total,
                                current: pageNum,
                                pageSize: pageSize,
                                showSizeChanger: false,
                                showTotal: (total) => `共 ${total} 条数据`,
                                onChange: (pageNum, pageSize) => setPageNum(pageNum)
                            }}
                        />
                    </div>
                    : parmObj.stepStatus == 1
                        ? <NextDetail isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn} />
                        : <CommissionDetail2 isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn} />
            }
            <Modal
                forceRender
                title='错误信息'
                visible={visible1}
                footer={false}
                onCancel={() => { setVisible1(false) }} >
                <p>累计已超过{errData.totalCount}行错误，请修改后重新上传</p>
                {
                    errData.list.map(item => <p key={item.id}>第{item.rowNumber}行 {item.name} {item.idNumber} ：{item.message}</p>)
                }
            </Modal>
            <Modal
                title='上传文件 '
                visible={visible2}
                onOk={methodsFn.handleUp}
                confirmLoading={loading1}
                onCancel={() => { setVisible2(false)  }}>
                <Form
                    form={form2}
                    {...formItemLayout}
                    initialValues={{}}>
                    <Form.Item label="温馨提示" style={{ marginTop: '-20px', marginBottom: '18px',alignItems: 'start' }} >
                        <div style={{ textAlign: 'left',lineHeight: '32px'}} >
                            <div>同一身份证号在1次批量导入中不能重复</div>
                        </div>
                    </Form.Item>
                    <Form.Item label="企业名称" >
                        <Input value={localStorage.getItem('companyName')} disabled={true} />
                    </Form.Item>
                    <Form.Item label="任务名称" name='taskName' rules={[{ required: true, message: '请输入任务名称' }]} >
                        <Input placeholder='请输入任务名称' />
                    </Form.Item>
                    <Form.Item label="发放文件" name="ffile" rules={[{ required: true, message: '上传文件' }]}>
                        <Upload {...fileProps}>
                            <Button style={{marginRight: '20px'}} icon={<UploadOutlined />}>点击上传</Button>
                            <a onClick={() => window.location.href = `/hire/common/getTemplateDownloadUrl?token=${localStorage.getItem("token")}&mobile=${localStorage.getItem("mobile")}&type=QY_RWSC`}>下载模板</a>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title='生成结算单'
                visible={visible}
                confirmLoading={loading}
                onOk={methodsFn.handleOk}
                onCancel={() => setVisible(false)}>
                <p>确定生成结算单数据？</p>
            </Modal>
        </div>
    )
}

export default CommissionUpload
