// 线上明细
import React, { useState, useEffect } from 'react'
import {
    Row,
    Table,
    Form,
    Input,
    Button,
    Space,
    message,
    Popconfirm,
    Modal,
    PageHeader,
} from 'antd';
import { withRouter } from "react-router-dom";
import { request } from '../../../../utils/fetch'
import { useDebounce, useThrottle } from '../../../../utils/auth'
import store from '../../../../store/index'
const FormItem = Form.Item;
const itemStyle = {
    width: '300px',
    marginRight: '15px',
    marginBottom: '0px'
}
const labelStyleObj = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 }
}
const resultLeft = {
    display: 'flex',
    background: '#fff',
    marginTop: '20px',
    alignItems: 'center',
    padding: '20px 0 0 30px',
}
function NextDetail(props) {
    let path = 'company:BillUpload'
    const [form] = Form.useForm();
    const { parmObj } = props
    const [tableData, setTableData] = useState([])
    const [visible, setVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [resultObj, setResultObj] = React.useState({
        TG: {
            rowCount: ''
        },
        JRSH: {
            rowCount: ''
        }
    });

    const pageSize = 20     //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)

    let columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align: 'center',

        }, {
            title: '身份证号',
            dataIndex: 'idNumber',
            key: 'idNumber',
            width: 150,
            align: 'center',

        },{
            title: '承揽费用（元)',
            dataIndex: 'commissionAmount',
            key: 'commissionAmount',
            width: 150,
            align: 'center',

        },
        {
            title: '落地方案',
            dataIndex: 'planTypeName',
            key: 'planTypeName',
            width: 150,
            align:'center',
            
        },
    ];
    if (parmObj.lineType != 1) {
        columns.push({
            title: '收款卡号',
            dataIndex: 'bankNumber',
            key: 'bankNumber',
            width: 150,
            align: 'center',

        }, {
            title: '开户行',
            dataIndex: 'bankName',
            key: 'bankName',
            width: 150,
            align: 'center',

        })
    }

    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <div>
                    <div style={resultLeft}>
                        <div style={{margin:'5px 0'}}>
                            <Space size="large">
                                <span style={{margin:'0 5px'}}>任务单号：{resultObj.billNo}</span>
                                <span style={{margin:'0 5px'}}>结算通道：{resultObj.taxEnterpriseName}</span>
                                <span style={{margin:'0 5px'}}>发放人数：{resultObj.rowCount}人</span>
                            </Space>
                        </div>
                        <div style={{margin:'5px 0'}}>
                            <Space size="large">
                                <span style={{margin:'0 5px'}}>承揽费用：￥{resultObj.commissionAmountCount}</span>
                                <span style={{margin:'0 5px'}}>平台服务费：￥{resultObj.serviceAmountCount}</span>
                                <span style={{margin:'0 5px'}}>合计放款：￥{resultObj.totalAmountCount}</span>
                            </Space>
                        </div>
                        <div>
                        </div>

                    </div>
                    <Form form={form} >
                        <Row>
                            <FormItem label="关键字" name="keyword" style={itemStyle} {...labelStyleObj}>
                                <Input placeholder="姓名丨身份证号" />
                            </FormItem>

                            <FormItem style={{ float: 'right', whiteSpace: 'nowrap', marginBottom: '0px' }} >
                                <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                                {
                                store.getState().userReducer.permissions.indexOf(`${path}:createSettlement`) > -1 && 
                                <Button type="primary" onClick={() => setVisible(true)} style={{marginLeft: '10px'}} >生成结算单</Button>
                                }
                                {
                                store.getState().userReducer.permissions.indexOf(`${path}:removeSettlement`) > -1 && 
                                <Popconfirm
                                    title={`确定放弃生成结算单么？`}
                                    onConfirm={() => methodsFn.handleCancel()}
                                    okText="确定"
                                    cancelText="取消"
                                >
                                    <Button style={{ marginLeft: 10 }} >放弃生成</Button>
                                </Popconfirm>}
                            </FormItem>
                        </Row>
                    </Form>
                </div>
            );
        },

    }
    //方法函数
    const methodsFn = {
        handleSearch() {
            setPageNum(1)
            var obj = {
                keyword: form.getFieldsValue().keyword,
                billNo: parmObj.billNo,
            }
            methodsFn.getTableList(obj)
        },
        handleFormReset() {
            form.resetFields();
        },
        async getTableList(searchObj) {
            let result = await request.get('/company/billUpload/getPreviewList', {
                ...searchObj,
                pageSize: pageSize,
                pageNum: pageNum,
            })
            setTotal(result.totalCount)
            setTableData(result.list)
        },
        getImportStat() {
            request.get('/company/billUpload/getBillStat', {
                billNo: parmObj.billNo,
            }).then(res => {
                console.log(res)
                setResultObj(res)
            })
        },
        handleOk: useThrottle(()=> {
            setLoading(true);
            request.post('/company/billUpload/createSettlement', {
                billNo: parmObj.billNo,
            }).then(res => {
                setVisible(false);
                setLoading(false);
                message.success(res.msg)
                props.callFn()
            }).catch(() => setLoading(false))
        }, 1000),
        handleCancel() {
            request.post('/company/billUpload/removeSettlement', {
                billNo: parmObj.billNo,
            }).then(res => {
                message.success(res.msg)
                props.callFn()
            }).catch(() => setLoading(false))
        }

    }
    useEffect(() => {
        methodsFn.getImportStat()

    }, [])
    useEffect(() => {
        var obj = {
            keyword: form.getFieldsValue().keyword,
            billNo: parmObj.billNo,
        }
        methodsFn.getTableList(obj)

    }, [pageNum])

    return (
        <div>
            <PageHeader className="site-page-header" title="明细" onBack={props.callFn} subTitle=""></PageHeader>
            {myComponent.renderSearchForm()}
            <Table
                scroll={{ x: 'max-content' }}
                columns={columns}
                dataSource={tableData}
                rowKey={(record) => record.idNumber}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: (total) => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum)
                }}
            />
            <Modal
                title='确认框'
                visible={visible}
                confirmLoading={loading}
                onOk={methodsFn.handleOk}
                onCancel={() => setVisible(false)}>
                <p>确定生成结算单数据？</p>
            </Modal>
        </div>
    )
}

export default withRouter(NextDetail)
