import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import {request} from '../../../utils/fetch'
import './home.css'
import item1 from '../../../images/item1.png'
import item2 from '../../../images/item2.png'
import item3 from '../../../images/item3.png'
import item4 from '../../../images/item4.png'
import ChartPlots from './ChartPlots.js'
import ChartLine from './ChartLine.js'

function Home() {
    const [dataObj, setDataObj] = React.useState({});
    
    const getIndexData = () => {
       request.get('/company/index/indexData').then(res => {
        setDataObj(res)
       })
    }
    useEffect(() => {
        getIndexData()
    },[])
    return (
        <div>
            <div className='home-banner' >
                <div>
                    <div className='head-title-text' >用工管理系统</div>
                    <div className='head-des-text' >助力千万家企业实现降本增效</div>
                </div>
            </div>
            <div className='overview' >
                <div className="home-title home-mrbtm">概况</div>
                <div className='overviews' >
                    <div className='item item1' >
                        <div>
                            <div className='itemt' >任务总数（次）</div>
                            <div className='itemc'>{dataObj.billCount}</div>
                        </div>
                        <img className='itemimg' src={item1} alt="" />
                    </div>
                    <div className='item item2' >
                        <div>
                            <div className='itemt' >参与任务人数（人）</div>
                            <div className='itemc'>{dataObj.staffCount}</div>
                        </div>
                        <img className='itemimg' src={item2} alt="" />
                    </div>
                    <div className='item item3' >
                        <div>
                            <div className='itemt' >任务总金额（元）</div>
                            <div className='itemc'>{dataObj.billAmount}</div>
                        </div>
                        <img className='itemimg' src={item3} alt="" />
                    </div>
                    <div className='item item4' >
                        <div>
                            <div className='itemt' >申请开票（次）</div>
                            <div className='itemc'>{dataObj.ticketCount}</div>
                        </div>
                        <img className='itemimg' src={item4} alt="" />
                    </div>
                </div>
            </div>
            <Row justify="space-between" gutter={16} style={{marginTop:'20px'}}>
                <Col span={12} className="home-bottom">
                    <div className="chartbox">
                        <div className="home-title home-mrbtm">近6个月的任务数（次）</div>
                        <div style={{padding: '20px'}}>
                            {dataObj.billCountList&&<ChartPlots vlabel="任务统计" color="#458EFF" data={dataObj.billCountList} />}
                        </div>
                    </div>
                </Col>
                <Col span={12} className="home-bottom">
                    <div className="chartbox">
                        <div className="home-title home-mrbtm">近6个月的承揽费用（元）</div>
                        <div style={{padding: '20px'}}>
                            {dataObj.billAmountList&&<ChartLine data={dataObj.billAmountList} />}
                        </div>
                    </div>
                </Col> 
            </Row>
        </div>
    )
}

export default Home
