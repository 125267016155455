import React,{useEffect,useState} from 'react'
import { Layout, Menu } from 'antd';
import * as Icon from '@ant-design/icons';

// import {menuList} from '../../menuList.js'
import {Link,withRouter} from "react-router-dom";
import MyHeader from './MyHeader'
// 动态渲染路由如果报错，用wintRouter包裹一下

const { SubMenu } = Menu;
const MenuItem = Menu.Item
const { Content, Sider, Footer } = Layout;
let menuList = []
function Index(props) {
  let pathname = props.location.pathname
  const createIcon = function(name) {
    return React.createElement(Icon[name],null)
   }

  try {
    menuList = JSON.parse(localStorage.getItem('menu')) || []
  } catch (error) {
    
  }
  const [openKeys,setOpenKeys] = useState([''])
  useEffect(() => {  
    menuList.forEach(subMenu => {
      if(subMenu.children){
        subMenu.children.forEach(item => {
          if(item.url === pathname){   //刷新页面展开对应菜单
            setOpenKeys([String(subMenu.id)])
          }
        })
      }else{

      }
    })
  },[pathname])    //监听路由变化

  //菜单渲染
  const getMenuItem = list => {
      return list.map((item, index) => {
          if (item.children && item.children.length > 0) {
              return (
                  <SubMenu
                      key={item.id}
                      icon={item.icon ? createIcon(item.icon):''} 
                      title={item.menuName}
                  >
                      {getMenuItem(item.children)}
                  </SubMenu>
              )
          } else {
              return (
                  <MenuItem key={item.url}>
                      <Link to={item.url}>
                        <span>{item.menuName}</span>
                        
                      </Link>
                  </MenuItem>
              )
          }
      })
  }
    return (
        <Layout>
          <MyHeader />
          <Layout className="site-layout">
            <Sider width={200}>
              <Menu
                mode="inline"
                selectedKeys={pathname}
                openKeys={openKeys}
                onOpenChange={(keys) => setOpenKeys(keys)}
                style={{borderRight: 0 }}
              >
              <MenuItem key="/admin/home" icon={createIcon('HomeOutlined')}>
                <Link to='/admin/home'/>
                  首页
              </MenuItem>
                {getMenuItem(menuList)}
              </Menu>
            </Sider>
            <Content>
              <Content className="myContent" >
                {props.children}
              </Content>
              <Footer className='myFooter' >Copyright © 智酬版权所有 技术支持: 智酬-专业灵活用工系统服务商</Footer>
            </Content>
          </Layout>
        </Layout>
    )
}

export default withRouter(Index)

