import React, { useImperativeHandle, useState, forwardRef } from 'react'
import { Modal, Image } from 'antd'
const ViewModel = (props,ref) => {
    const [visible, setVisible] = useState(false)
    const [rowData, setRowData] = useState([]) //公司列表
    // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        showModal: (record) => {
            setVisible(true)
            setRowData(record)
        },
    }))
    const onCancel = () => {
        setVisible(false)
    }
    return (
        <Modal maskClosable={false} title="身份证照" visible={visible} onCancel={onCancel} footer={null} width={740}>
          <div style={{display: 'flex'}}>
          {
            !!rowData.positivePictureUrl && <Image src={rowData.positivePictureUrl} width={300} />
          }
          {
            !!rowData.reversePictureUrl && <Image src={rowData.reversePictureUrl} width={300} />
          }
          </div>
      </Modal>
    )
}
export default forwardRef(ViewModel)
